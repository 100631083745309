import classNames from "classnames";
import React, { useContext } from "react";
import { MatchingIcon, PickupReady, ShippingReady } from "../../../../../assets/icons/icons";
import StatPill from "../../../../../components/StatPill";
import { OverviewContext } from "../../../../../context/Overview";
import theme from "../../../../../theme";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export default function InfoPills({ className, ...props }: Props) {
  const {
    userShips,
    counts: { readyToShip, readyForPickup, readyToMatch },
    isLoading,
    returnByMailUrl,
  } = useContext(OverviewContext);

  return userShips === undefined ? null : (
    <div
      {...props}
      className={classNames(`d-flex flex-column flex-lg-row gap-35 w-100`, className)}
    >
      {!userShips ? null : (
        <StatPill
          count={readyToShip}
          icon={
            <ShippingReady
              accessibilityTitle="Go to shipments"
              ariaHidden={true}
              titleId="OverviewGoToShipment"
            />
          }
          loading={isLoading}
          href={`/partner/returns${returnByMailUrl}&status=PENDING&page=1`}
          title="Return by mail"
          data-testid="readyToShip"
        />
      )}

      <StatPill
        count={readyForPickup}
        icon={
          <PickupReady
            accessibilityTitle="Go to pickups"
            ariaHidden={true}
            titleId="OverviewGoToPickups"
          />
        }
        loading={isLoading}
        href="/partner/returns?recoveryMethod=LOCAL_PICKUP&status=PENDING&page=1"
        title="Return in person"
        data-testid="readyForPickup"
      />

      <StatPill
        count={readyToMatch}
        icon={
          <MatchingIcon
            color1={theme.colors.lightBlue}
            color2={theme.colors.primaryBlue}
            accessibilityTitle="Go to matches"
            ariaHidden={true}
            titleId="OverviewGoToMatches"
          />
        }
        loading={isLoading}
        href="/partner/matches"
        title="Ready to match"
        data-testid="readyToMatch"
      />
    </div>
  );
}
