import { ALL_OPTION } from "client/hooks";
import { useHasStorageLocations } from "client/hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import { generateAllOption } from "client/hooks/data/partner/partnerReturnCounts";
import useCategoryCounts from "client/hooks/data/partner/partnerReturnCounts/useCategoryCounts";
import useStatusCounts from "client/hooks/data/partner/partnerReturnCounts/useStatusCounts";
import useStorageLocationCounts from "client/hooks/data/partner/partnerReturnCounts/useStorageLocationCounts";
import { useSearchParamsWithZod } from "client/hooks/useSearchParamsWithZod";
import { RecoveryMethodsEnum } from "client/types";
import { FilterSelect } from "client/ui-components/Select";
import React, { useMemo } from "react";
import { z } from "zod";
import useReturnFilters from "../../helpers/hooks/useReturnsFilters";
import { useReturnsStore } from "../../returnStore";
import { FiltersContainer, Toolbar } from "./styles";

export const useReturnsFiltersFromQuery = () => {
  const { searchParams, setSearchParams, remove } = useSearchParamsWithZod({
    custom_status: z.string().default(ALL_OPTION),
    category: z.string().default(ALL_OPTION),
    storage_location: z.string().default(ALL_OPTION),
  });

  const hasFilters = Object.keys(searchParams).some(key => searchParams[key] !== ALL_OPTION);

  return {
    filters: searchParams,
    setFilters: setSearchParams,
    removeFilter: remove,
    hasFilters,
  };
};

const useRecoveryMethod = () => {
  const {
    searchParams: { recoveryMethod },
  } = useSearchParamsWithZod({
    recoveryMethod: z
      .nativeEnum(RecoveryMethodsEnum)
      .or(z.literal(ALL_OPTION))
      .catch(() => ALL_OPTION),
  });
  return recoveryMethod;
};

const StatusFilter = () => {
  const recoveryMethod = useRecoveryMethod();
  const { filters, setFilters, removeFilter } = useReturnsFiltersFromQuery();
  const formattedFilters = useReturnFilters({ count: 0 });
  const { data: statusCounts } = useStatusCounts({
    include_filtered_counts: "True",
    ...formattedFilters,
  });
  const { statusCounts: allStatusCounts } = useReturnsStore(state => state.filterCounts);

  const countsToUse =
    (!filters.custom_status || filters.custom_status === ALL_OPTION
      ? allStatusCounts
      : statusCounts) || [];
  const allCount = generateAllOption(countsToUse);

  const countsToUseWithAll = useMemo(() => [allCount, ...countsToUse], [allCount, countsToUse]);

  const onValueChange = (status: string) => {
    if (status === ALL_OPTION) {
      removeFilter("custom_status");
    } else {
      setFilters({ custom_status: status });
    }
  };
  if (recoveryMethod === RecoveryMethodsEnum.LOCAL_PICKUP) return null;
  return (
    <FilterSelect
      label="Status"
      value={filters.custom_status}
      onValueChange={onValueChange}
      options={countsToUseWithAll}
    />
  );
};

const CategoryFilter = () => {
  const { filters, setFilters, removeFilter } = useReturnsFiltersFromQuery();
  const formattedFilters = useReturnFilters({ count: 0 });
  const { categoryCounts: nestedCounts } = useCategoryCounts({
    include_filtered_counts: "True",
    ...formattedFilters,
  });
  const { categoryCounts } = useReturnsStore(state => state.filterCounts);

  const countsToUse =
    (!filters.category || filters.category === ALL_OPTION ? categoryCounts : nestedCounts) || [];
  const allCount = generateAllOption(countsToUse);

  const countsToUseWithAll = useMemo(() => [allCount, ...countsToUse], [allCount, countsToUse]);

  const onValueChange = (category: string) => {
    if (category === ALL_OPTION) {
      removeFilter("category");
    } else {
      setFilters({ category });
    }
  };

  return (
    <FilterSelect
      label="Category"
      value={filters.category}
      onValueChange={onValueChange}
      options={countsToUseWithAll}
    />
  );
};

const StorageLocationFilter = () => {
  const { filters, setFilters, removeFilter } = useReturnsFiltersFromQuery();
  const totalCount = useReturnsStore(state => state.totalCount);
  const formattedFilters = useReturnFilters({ count: 0 });
  const { storageLocationCounts: nestedCounts } = useStorageLocationCounts({
    storage_location: filters.storage_location,
    ...formattedFilters,
  });
  const { storageLocationCounts } = useReturnsStore(state => state.filterCounts);
  const hasOptionSelected = !filters.storage_location || filters.storage_location === ALL_OPTION;

  const countsToUse = (hasOptionSelected ? storageLocationCounts : nestedCounts) || [];
  const allCount = generateAllOption(countsToUse);
  if (hasOptionSelected) {
    allCount.count = totalCount;
  }

  const countsToUseWithAll = useMemo(() => [allCount, ...countsToUse], [allCount, countsToUse]);

  const onValueChange = storageLocation => {
    if (storageLocation === ALL_OPTION) {
      removeFilter("storage_location");
    } else {
      setFilters({ storage_location: storageLocation });
    }
  };

  return (
    <FilterSelect
      label="Storage location"
      value={filters.storage_location}
      onValueChange={onValueChange}
      options={countsToUseWithAll}
    />
  );
};

export function ReturnFilters() {
  const hasStorageLocations = useHasStorageLocations();
  return (
    <Toolbar>
      <FiltersContainer style={{ maxWidth: "1072px" }}>
        <StatusFilter />
        <CategoryFilter />
        {hasStorageLocations && <StorageLocationFilter />}
      </FiltersContainer>
    </Toolbar>
  );
}
