import { useQuery } from "@tanstack/react-query";
import { PartnerItemsCountByStorageResponse } from "client/types";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useMemo } from "react";
import { OptionalCountQueryFilters } from ".";
import { FilterItem } from "../../../../ui-components/FilterDropdown/types";
import queryFactory from "../queryFactory";
import useFetchStorageLocations from "../useFetchStorageLocations";

export const initialStorageLocationFilter: FilterItem = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

function formatStorageLocationCounts(
  storageLocations: { id: string; name: string }[],
  storageLocationCounts: PartnerItemsCountByStorageResponse,
): FilterItem[] {
  const counts = [
    {
      ...initialStorageLocationFilter,
      count: storageLocationCounts.reduce((acc, curr) => acc + curr.count, 0),
    },
    ...storageLocations.map(({ id, name }) => {
      const storage = storageLocationCounts.find(item => item.id === id);

      return {
        name,
        value: id,
        count: storage?.count || 0,
      };
    }),
  ];

  // TODO: We cannot show this Unassigned filter item until we have an ability
  // to filter by storage location for items that are unassigned.
  /*
  const unassignedItems = storageLocationCounts.find(
    (item) => item.id === null
  );
  if (unassignedItems) {
    counts.append({
      name: "Unassigned *",
      value: "",
      count: unassignedItems.count,
    });
  }
  */

  return counts;
}

export default function useStorageLocationCounts(queryObject: OptionalCountQueryFilters) {
  const partnerId = useCurrentPartner();
  const { storageLocations } = useFetchStorageLocations();
  const hasStorageLocations = (storageLocations?.length || 0) > 0;

  const { data: storageLocationCounts } = useQuery(
    queryFactory.itemCountByStorageFiltered({ partnerId, queryObject }),
  );

  const formattedStorageLocations = useMemo(() => {
    if (!storageLocationCounts || !storageLocations) return [];

    return formatStorageLocationCounts(storageLocations, storageLocationCounts);
  }, [storageLocationCounts, storageLocations]);
  return {
    hasStorageLocations,
    storageLocations: formattedStorageLocations,
  };
}

export function useHasStorageLocations() {
  const { storageLocations } = useFetchStorageLocations();
  return (storageLocations?.length || 0) > 0;
}
