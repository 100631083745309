import { usePagination, useSearchParam } from "client/hooks";
import { RETURNS_ALL } from "client/hooks/data/partner/partnerReturnCounts";
import useCategoryCounts from "client/hooks/data/partner/partnerReturnCounts/useCategoryCounts";
import usePartner from "client/hooks/data/partner/usePartner";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import usePartnerRecoveryMethods from "client/hooks/data/partner/usePartnerRecoveryMethods";
import { useSearchParamsWithZod } from "client/hooks/useSearchParamsWithZod";
import { PartnerReturnFilters, RecoveryMethodsEnum, ReturnStatus } from "client/types";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useMemo } from "react";
import { z } from "zod";

type useReturnFiltersProps = {
  count: number;
};

export default function useReturnFilters({ count = 0 }: useReturnFiltersProps) {
  const { partnerUserLocation } = usePartnerLocationStore();
  const { partner } = usePartner(useCurrentPartner());
  const {
    filteredRecoveryMethods: { data: partnerRecoveryMethods },
  } = usePartnerRecoveryMethods(partner?.recovery_methods);
  const { page, pageSize } = usePagination({ count });
  const [queryRecoveryMethod] = useSearchParam<RecoveryMethodsEnum | "all">(
    "recoveryMethod",
    "all",
  );
  const { searchParams: filterParams } = useSearchParamsWithZod({
    custom_status: z.string().default(RETURNS_ALL),
    category: z.string().default(RETURNS_ALL),
    storage_location: z.string().default(RETURNS_ALL),
  });
  const { categoryCounts: allCategories } = useCategoryCounts({});

  const {
    custom_status: queryStatus,
    category: queryCategory,
    storage_location: queryStorageLocation,
  } = filterParams;

  const selectedCategory = useMemo(
    () => allCategories?.find(({ value }) => value === queryCategory),
    [allCategories, queryCategory],
  );

  const returnFilters = useMemo<PartnerReturnFilters>(() => {
    return {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      ...(queryRecoveryMethod === null || queryRecoveryMethod === "all"
        ? {}
        : {
            recovery_method: partnerRecoveryMethods
              .filter(({ value }) => value === queryRecoveryMethod)
              .map(({ id }) => id),
          }),
      status: ReturnStatus.PENDING,
      ...(partnerUserLocation.id !== "" ? { lost_location: [partnerUserLocation.id] } : {}),
      custom_status: queryStatus === RETURNS_ALL ? undefined : queryStatus,
      storage_location: queryStorageLocation === RETURNS_ALL ? undefined : queryStorageLocation,
      category: selectedCategory?.value === RETURNS_ALL ? undefined : selectedCategory?.value,
      include_filtered_counts: "True",
    };
  }, [
    queryRecoveryMethod,
    partnerRecoveryMethods,
    partnerUserLocation,
    page,
    pageSize,
    queryStatus,
    queryStorageLocation,
    selectedCategory,
  ]);

  return returnFilters;
}
