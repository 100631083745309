import classnames from "classnames";
import React, { useContext, useEffect } from "react";
import { NoMatchesIcon, PickupReady, ShippingReady } from "../../../assets/icons/icons";
import OverviewMessage from "../../../components/OverviewMessage";
import ReturnsList from "../../../components/ReturnsList";
import TableBlankState from "../../../components/Table/TableBlankState";
import { MatchesColumns } from "../../../components/Table/TableColumns";
import { OverviewContext } from "../../../context/Overview";
import { isLgQuery } from "../../../helpers/mediaQuery";
import { mountBodyGray6, unmountBodyGray6 } from "../../../utils/bodyStyle";
import useCurrentPartner from "../../../utils/useCurrentPartner";
import { InfoPills } from "./components";
import OldMatches from "./components/Matches";
import { EmailText, Wrapper } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

const Overview: React.FC<Props> = ({ className, ...props }) => {
  const partnerId = useCurrentPartner();
  const isLg = isLgQuery();
  const {
    readyToShipItems,
    readyForPickupItems,
    counts: { readyForPickup, readyToMatch, readyToShip, totalClaims, totalPendingReturnsCount },
    isLoading,
    userShips,
    returnByMailUrl,
  } = useContext(OverviewContext);

  useEffect(() => {
    mountBodyGray6();

    return function cleanup() {
      unmountBodyGray6();
    };
  }, [isLg]);

  return userShips === undefined ? null : (
    <Wrapper {...props} className={classnames("my-45 gap-35 px-0", className)}>
      <OverviewMessage
        loading={isLoading}
        pendingReturnsCount={totalPendingReturnsCount}
        matchesCount={readyToMatch}
        totalClaims={totalClaims}
        data-testid="overviewMessage"
      />

      <InfoPills />

      {!isLg ? null : (
        <div className="d-flex flex-column flex-xl-row gap-35">
          {userShips && (
            <ReturnsList
              blankStateMessage="You have no returns by mail"
              count={readyToShip}
              items={readyToShipItems}
              isLoading={isLoading}
              seeAllLink={`/partner/returns${returnByMailUrl}&status=PENDING&page=1`}
              title="Return by mail"
              titleIcon={
                <ShippingReady
                  accessibilityTitle="Shipments"
                  ariaHidden={true}
                  titleId="OverviewDisplayShipments"
                />
              }
              data-testid="readyToShipList"
            />
          )}

          <ReturnsList
            blankStateMessage="You have no in-person returns"
            count={readyForPickup}
            items={readyForPickupItems}
            seeAllLink="/partner/returns?recoveryMethod=LOCAL_PICKUP&status=PENDING&page=1"
            title="Return in person"
            isLoading={isLoading}
            titleIcon={
              <PickupReady
                accessibilityTitle="Pickups"
                ariaHidden={true}
                titleId="OverviewDisplayPickups"
              />
            }
            data-testid="readyForPickupList"
          />
        </div>
      )}

      {isLg && (
        <>
          {readyToMatch > 0 ? (
            <OldMatches
              className="m-0"
              count={readyToMatch}
              limit={3}
              partnerId={partnerId}
              title="Potential matches"
            />
          ) : (
            <TableBlankState
              columns={MatchesColumns}
              icon={
                <NoMatchesIcon
                  className="w-100"
                  accessibilityTitle="No potential matches"
                  ariaHidden={true}
                  titleId="TableBlankStateNoMatchesTitle"
                />
              }
              loading={isLoading}
              message="You have no potential matches"
              title="Potential matches"
            />
          )}
        </>
      )}

      <EmailText className="mt-0 mt-lg-35 text-center fw500">
        If you have any questions, please reach out to us at{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="fw700"
          href="mailto:support@thanksboomerang.com"
        >
          support@thanksboomerang.com
        </a>
        .
      </EmailText>
    </Wrapper>
  );
};

export default Overview;
