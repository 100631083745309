import { ClaimDetailsContext } from "client/context/ClaimDetails";
import { RecoveryMethodsEnum } from "client/types";
import { If } from "client/utils/If";
import React, { useContext, useState } from "react";
import { isLgQuery } from "../../../../../helpers/mediaQuery";
import { Button, Text } from "../../../../../ui-components";
import { ClaimOptionsModal } from "../../ClaimOptionsModal";
import awaitingPaymentIcon from "./awaitingPaymentIcon.svg";

export default function AwaitingPaymentActionBar() {
  const [claimOptionsVisible, setClaimOptionsVisible] = useState(false);
  const isLg = isLgQuery();
  const { claim, returnResponse, shipment, partnerRecoveryMethods } =
    useContext(ClaimDetailsContext);
  const hasLocalPickup = partnerRecoveryMethods?.includes(RecoveryMethodsEnum.LOCAL_PICKUP);

  return claim === null ||
    claim === undefined ||
    returnResponse === undefined ||
    returnResponse === null ? null : (
    <div className="d-flex flex-row justify-content-between flex-grow-1">
      <div
        className="d-none d-lg-flex flex-row gap-25 align-items-center"
        data-testid="paymentDetailsRequiredText"
      >
        <img src={awaitingPaymentIcon} alt="Awaiting payment" />

        <Text fontWeight={700}>
          Payment details <br /> required!
        </Text>
      </div>
      <div className="d-flex flex-column flex-lg-row-reverse justify-content-lg-start gap-lg-3 flex-grow-1">
        <Button
          aria-label="enter payment details"
          className="flex-shrink-0 mb-25 mb-lg-0"
          data-testid="enterPaymentDetailsButton"
          href={`/shippingcheckout?return_object=${returnResponse?.id || ""}&claim_id=${claim.id}${
            shipment?.id ? `&shipment_id=${shipment?.id}` : ""
          }`}
          size="2xl"
          fullWidth={!isLg}
        >
          Enter payment details
        </Button>
        <If condition={hasLocalPickup}>
          <Button
            aria-label="claim options"
            className="flex-shrink-0"
            data-testid="claimOptionsButton"
            onClick={() => setClaimOptionsVisible(true)}
            size="2xl"
            variant="outline"
            fullWidth={!isLg}
          >
            Claim options
          </Button>
        </If>
      </div>
      <ClaimOptionsModal isOpened={claimOptionsVisible} setIsOpened={setClaimOptionsVisible} />
    </div>
  );
}
