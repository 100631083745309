import { ApiHandlerRes, useApiHandler } from ".";
import {
  GetPartnerReturnsRequest,
  PartnerReturn,
  PartnerReturnByIdRequest,
  PartnerReturnsCountByRecoveryMethodRequest,
  PartnerReturnsCountByRecoveryMethodResponse,
  PartnerReturnsResponse,
} from "../types";
import { UpdatePartnerReturnsRequest as UpdatePartnerReturnRequest } from "../types/actions";
import fetchData from "../utils/fetchData";
import getQueryString from "../utils/getQueryString";
import { getCommonHeaders } from "../utils/headers";

const getPartnerReturnById = async (
  partnerReturnIdReq: PartnerReturnByIdRequest,
): Promise<PartnerReturn | null> => {
  return fetchData(
    `/api/partner/partners/${partnerReturnIdReq.partnerId}/returns/${partnerReturnIdReq.returnId}/`,
  );
};

export const useGetPartnerReturnById = (): ApiHandlerRes<
  PartnerReturnByIdRequest,
  PartnerReturn | null
> => useApiHandler(getPartnerReturnById);

export const getReturns = async (
  partnerReturnsReq: GetPartnerReturnsRequest,
): Promise<PartnerReturnsResponse | null> => {
  const queryString = partnerReturnsReq.filters ? getQueryString(partnerReturnsReq.filters) : "/";
  return fetchData(`/api/partner/partners/${partnerReturnsReq.partnerId}/returns/${queryString}`);
};

export const updatePartnerReturn = (
  returnRequest: UpdatePartnerReturnRequest,
): Promise<PartnerReturnsResponse | null> => {
  return fetchData(
    `/api/partner/partners/${returnRequest.partnerId}/returns/${returnRequest.id}/`,
    {
      method: "PATCH",
      headers: getCommonHeaders(),
      body: JSON.stringify({
        recovery_method: returnRequest.recoveryMethod,
      }),
    },
  );
};

export const completeReturn = (
  partnerId: string,
  returnId: string,
): Promise<PartnerReturn | null> => {
  return fetchData(`/api/partner/partners/${partnerId}/returns/${returnId}/complete/`, {
    method: "POST",
    headers: getCommonHeaders(),
  });
};

// Query for Return count by Recovery Method
export const getReturnsCountByRecoveryMethodFromPartnerId = async (
  partnerReturnsCountByRecoveryMethodReq: PartnerReturnsCountByRecoveryMethodRequest,
): Promise<PartnerReturnsCountByRecoveryMethodResponse | null> => {
  const { partnerId, filters = {} } = partnerReturnsCountByRecoveryMethodReq;
  return fetchData(
    `/api/partner/partners/${partnerId}/returns/count-by-recovery-method/${getQueryString(
      filters,
    )}`,
  );
};

export const useCompleteReturn = (): ApiHandlerRes<string, PartnerReturn | null> =>
  useApiHandler(completeReturn);

export const useCompletePartnerReturn = (): ApiHandlerRes<string, PartnerReturn | null> =>
  useApiHandler(completeReturn);

export const useReturnsCountByRecoveryMethodFromPartnerId = (): ApiHandlerRes<
  PartnerReturnsCountByRecoveryMethodRequest,
  PartnerReturnsCountByRecoveryMethodResponse | null
> =>
  useApiHandler<
    PartnerReturnsCountByRecoveryMethodRequest,
    PartnerReturnsCountByRecoveryMethodResponse | null
  >(getReturnsCountByRecoveryMethodFromPartnerId);
