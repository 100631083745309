import { queryOptions } from "@tanstack/react-query";
import { GetUserClaimsRequest } from "client/types";
import fetchCategories from "./fetchFunctions/fetchCategories";
import fetchClaim from "./fetchFunctions/fetchClaim";
import fetchClaims from "./fetchFunctions/fetchClaims";
import fetchCountries from "./fetchFunctions/fetchCountries";
import fetchCountry from "./fetchFunctions/fetchCountry";
import fetchImagesWithClaimId from "./fetchFunctions/fetchImagesWithClaimId";
import fetchLocations from "./fetchFunctions/fetchLocations";
import fetchLocationWithSlug from "./fetchFunctions/fetchLocationWithSlug";
import fetchPartnerBySlug from "./fetchFunctions/fetchPartner";
import fetchRecoveryMethods from "./fetchFunctions/fetchRecoveryMethods";
import fetchState from "./fetchFunctions/fetchState";
import fetchStates from "./fetchFunctions/fetchStates";

// Query key factory for all user-related queries.
//
// Inspired by:
//
// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
// https://tkdodo.eu/blog/the-query-options-api#query-factories
const queries = {
  all: () => ["user"],
  categories: () =>
    queryOptions({
      queryKey: [...queries.all(), "categories"],
      queryFn: fetchCategories,
      select: data => data.results,
    }),
  claims: () => [...queries.all(), "claims"],
  claimsFiltered: (parameters: GetUserClaimsRequest) =>
    queryOptions({
      queryKey: [...queries.claims(), parameters],
      queryFn: () => fetchClaims(parameters),
    }),
  claim: (claimId: string | null) =>
    queryOptions({
      queryKey: [...queries.claims(), claimId],
      queryFn: () => fetchClaim(claimId ?? ""),
      enabled: !!claimId,
    }),
  claimImages: (claimId: string | null) =>
    queryOptions({
      queryKey: [...queries.claim(claimId).queryKey, "images"],
      queryFn: () => fetchImagesWithClaimId(claimId ?? ""),
      enabled: !!claimId,
    }),
  countries: () =>
    queryOptions({
      queryKey: [...queries.all(), "countries"],
      queryFn: fetchCountries,
    }),
  country: (countryId: string | null) =>
    queryOptions({
      queryKey: [...queries.countries().queryKey, countryId],
      queryFn: () => fetchCountry(countryId ?? ""),
      enabled: !!countryId,
    }),
  locations: () => [...queries.all(), "locations"],
  locationsForPartner: (partnerId: string) =>
    queryOptions({
      queryKey: [...queries.all(), "locations", partnerId],
      queryFn: () => fetchLocations({ partner: partnerId }),
      enabled: !!partnerId,
      select: data => data.results,
    }),
  locationWithSlug: (slug: string) =>
    queryOptions({
      queryKey: [...queries.locations(), slug],
      queryFn: () => fetchLocationWithSlug(slug),
      enabled: !!slug,
    }),
  recoveryMethods: () =>
    queryOptions({
      queryKey: [...queries.all(), "recoveryMethods"],
      queryFn: fetchRecoveryMethods,
      select: data => data.results,
    }),
  states: () => [...queries.all(), "states"],
  statesFiltered: (search?: string) =>
    queryOptions({
      queryKey: [...queries.states(), search],
      queryFn: () => fetchStates(search),
    }),
  state: (stateId: string) =>
    queryOptions({
      queryKey: [...queries.states(), stateId],
      queryFn: () => fetchState(stateId),
      enabled: !!stateId,
    }),

  partners: () => [...queries.all(), "partners"],
  partnerBySlug: (slug: string) =>
    queryOptions({
      queryKey: [...queries.partners(), slug],
      queryFn: () => fetchPartnerBySlug(slug),
      enabled: !!slug,
    }),
};

export default queries;
