import { ItemStatus, RecoveryMethod, RecoveryMethodsEnum, ReturnStatus } from "../../types";
import { InventoryTab, InventoryTabsName, ReturnsTab, ReturnsTabsName } from "./types";

export const InventoryArchiveTabs: InventoryTab[] = [
  {
    name: InventoryTabsName.UNCLAIMED,
    statuses: [ItemStatus.UNCLAIMED],
  },
];

export const getReturnsTabs = (partnerRecoveryMethods: RecoveryMethod[]): ReturnsTab[] => {
  const partnerRecoveryMethodsValues = partnerRecoveryMethods.reduce(
    (acc: RecoveryMethodsEnum[], curr: RecoveryMethod) => {
      acc.push(curr.value);
      return acc;
    },
    [],
  );

  const tabs: ReturnsTab[] = [
    {
      name: ReturnsTabsName.ALL,
      recoveryMethods: partnerRecoveryMethodsValues,
      statuses: [ReturnStatus.PENDING],
    },
  ];
  if (partnerRecoveryMethodsValues.length === 1) return tabs;

  if (partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.LOCAL_PICKUP)) {
    tabs.push({
      name: ReturnsTabsName.RETURN_IN_PERSON,
      recoveryMethods: [RecoveryMethodsEnum.LOCAL_PICKUP],
      statuses: [ReturnStatus.PENDING],
    });
  }
  if (
    partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.SHIPPING_COST_COVERED) ||
    partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED)
  ) {
    const returnByMailRecoveryMethods: RecoveryMethodsEnum[] = [];
    if (partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED))
      returnByMailRecoveryMethods.push(RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED);
    if (partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.SHIPPING_COST_COVERED))
      returnByMailRecoveryMethods.push(RecoveryMethodsEnum.SHIPPING_COST_COVERED);
    tabs.push({
      name: ReturnsTabsName.RETURN_BY_MAIL,
      recoveryMethods: returnByMailRecoveryMethods,
      statuses: [ReturnStatus.PENDING],
    });
  }
  return tabs;
};

export const getArchiveReturnsTabs = (partnerRecoveryMethods: RecoveryMethod[]): ReturnsTab[] => {
  const partnerRecoveryMethodsValues = partnerRecoveryMethods.reduce(
    (acc: RecoveryMethodsEnum[], curr: RecoveryMethod) => {
      acc.push(curr.value);
      return acc;
    },
    [],
  );
  const recoveryMethods: RecoveryMethodsEnum[] = [];

  if (partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.LOCAL_PICKUP))
    recoveryMethods.push(RecoveryMethodsEnum.LOCAL_PICKUP);
  if (partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED))
    recoveryMethods.push(RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED);
  if (partnerRecoveryMethodsValues.includes(RecoveryMethodsEnum.SHIPPING_COST_COVERED))
    recoveryMethods.push(RecoveryMethodsEnum.SHIPPING_COST_COVERED);
  const tabs: ReturnsTab[] = [
    {
      name: ReturnsTabsName.ARCHIVE,
      recoveryMethods,
      statuses: [ReturnStatus.COMPLETED],
    },
  ];
  return tabs;
};
