import classNames from "classnames";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import React, { useContext } from "react";

import { ReturnStatus as ReturnStatusIcon } from "@assets/icons/icons";
import { PartnerGlobalSearchContext } from "client/context/PartnerGlobalSearch";
import { isLgQuery, isXsQuery, isXxxlQuery } from "client/helpers/mediaQuery";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { ResultType } from "../..";
import history from "../../../../context/history";
import theme from "../../../../theme";
import {
  ClaimStatus,
  ItemStatus,
  PartnerShipment,
  RecoveryMethod,
  ReturnStatus,
  UnclaimedStatus,
  User,
} from "../../../../types/index";
import { Typography } from "../../../../ui-components";
import ResultCard from "../ResultCard";
import { Container, FooterContainer, HeaderContainer, Row, TableHeader } from "./styles";

export type ReturnObject = {
  id: string;
  recovery_method?: RecoveryMethod;
  shipment: PartnerShipment;
  match: string;
  status: string;
};

export type Result = {
  id: string;
  name: string;
  created_at?: string;
  lost_at?: string;
  collected_at?: string;
  status: ClaimStatus | ItemStatus | ReturnStatus;
  unclaimed_status?: UnclaimedStatus;
  description: string;
  user: User;
  return_object: ReturnObject;
};

type ResultsSectionProps = {
  results: Result[];
  header: string;
  isReturns?: boolean;
  showSeeAll?: boolean;
  subheader: string;
  className?: string;
  type: ResultType;
  seeAllLinkText?: string;
  dataTestId?: string;
};

export default function ResultsSection({
  results,
  header,
  showSeeAll,
  subheader,
  className,
  type,
  seeAllLinkText,
  dataTestId,
}: ResultsSectionProps) {
  const { lastVisitedPage, setShowAllResults, setResultSetType, searchTerm, locationFilter } =
    useContext(PartnerGlobalSearchContext);
  const isLg = isLgQuery();
  const isXs = isXsQuery();
  const isXxxl = isXxxlQuery();
  const dateFormatter = useDateFormatter();

  return (
    <Container className={classNames("mx-3", className)} data-testid={dataTestId}>
      <HeaderContainer>
        <Typography
          heading
          as={"h3"}
          fontWeight={theme.fontWeights.bold}
          fontSize={theme.fontSizes.md}
          color={theme.ui.colors.primary700}
          lineHeight={isXs ? 1.7 : 1.35}
          className="mb-0 ms-3"
        >
          {header}
        </Typography>
        <Typography
          fontWeight={theme.fontWeights.light}
          fontSize={theme.fontSizes.sm}
          color={theme.ui.colors.primary700}
          className="ms-3"
          lineHeight={1.75}
        >
          {subheader}
        </Typography>
      </HeaderContainer>

      {isLg && (
        <TableHeader className="p-35">
          <Row className="row">
            <div
              className={`col${
                type === ResultType.ITEMS && isXxxl ? "-2" : type === ResultType.ITEMS ? "-3" : ""
              }`}
            >
              <Typography
                as={"div"}
                fontSize="14px"
                fontWeight={theme.fontWeights.light}
                lineHeight={1.45}
                whiteSpace="pre"
                color={theme.ui.colors.primary700}
              >
                {type === ResultType.ITEMS ? "Found item" : "Lost item"}
              </Typography>
            </div>
            <div className={`col${type === ResultType.ITEMS ? "" : "-3"}`}>
              <Typography
                as={"div"}
                fontSize="14px"
                fontWeight={theme.fontWeights.light}
                lineHeight={1.45}
                whiteSpace="pre"
                color={theme.ui.colors.primary700}
              >
                {type === ResultType.ITEMS ? "ID" : "Contact details"}
              </Typography>
            </div>
            <div className="col">
              <Typography
                as={"div"}
                fontSize="14px"
                fontWeight={theme.fontWeights.light}
                lineHeight={1.45}
                whiteSpace="pre"
                color={theme.ui.colors.primary700}
              >
                {type === ResultType.ITEMS ? "Date found" : "Date lost"}
              </Typography>
            </div>

            <div className="col-2">
              <Typography
                as={"div"}
                fontSize="14px"
                fontWeight={theme.fontWeights.light}
                lineHeight={1.45}
                whiteSpace="pre"
                color={theme.ui.colors.primary700}
              >
                Description
              </Typography>
            </div>
            <div className="col-2">
              <Typography
                as={"div"}
                fontSize="14px"
                fontWeight={theme.fontWeights.light}
                lineHeight={1.45}
                whiteSpace="pre"
                color={theme.ui.colors.primary700}
              >
                Status
              </Typography>
            </div>
            <div className="col-3">
              <Typography
                as={"div"}
                fontSize="14px"
                fontWeight={theme.fontWeights.light}
                lineHeight={1.45}
                whiteSpace="pre"
                color={theme.ui.colors.primary700}
              >
                <ReturnStatusIcon
                  className="me-1"
                  accessibilityTitle="returnStatusIcon"
                  titleId="returnStatusIcon"
                />
                Return status
              </Typography>
            </div>
          </Row>
        </TableHeader>
      )}
      {(results || [])?.map((result, i) => (
        <ResultCard
          dataTestId={`${type}-result-${i}`}
          backgroundColor={i % 2 !== 0 ? theme.ui.colors.white : theme.ui.colors.gray300}
          type={type}
          key={result.id}
          id={result.id}
          name={result.name}
          created_at={
            result.created_at ? dateFormatter(new Date(result.created_at), "long") : undefined
          }
          lost_at={
            result.lost_at
              ? dateFormatter(convertToDateIgnoringTime(result.lost_at), "long")
              : undefined
          }
          collected_at={
            result.collected_at
              ? dateFormatter(convertToDateIgnoringTime(result.collected_at), "long")
              : undefined
          }
          description={result.description}
          user={result.user}
          result={result}
        />
      ))}
      {showSeeAll && (
        <FooterContainer>
          <Typography
            className="cursor-pointer ms-3"
            data-testid="viewAllLink"
            as="a"
            fontWeight={theme.fontWeights.bold}
            lineHeight={1.5}
            fontSize="16px"
            color={theme.colors.primaryBlue}
            onClick={() => {
              setResultSetType(type);
              setShowAllResults(true);
              history.push(
                `/partner/search/all/q?query=${encodeURIComponent(
                  searchTerm,
                )}${`&location_id=${locationFilter}`}${`&result_set_type=${type}`}`,
                {
                  query: history.location.search,
                  pathname: history.location.pathname,
                  searchInitiator: lastVisitedPage,
                },
              );
            }}
          >
            {seeAllLinkText}
          </Typography>
        </FooterContainer>
      )}
    </Container>
  );
}
