import { PartnerLocationFragment } from "client/hooks/data/partner/usePartnerLocationStore";
import { getPartnerItemFromId } from "../../actions/partnerItems";
import { getMatchFromId } from "../../actions/partnerMatches";
import { getReturns } from "../../actions/partnerReturns";
import { PartnerItem, ReturnStatus } from "../../types/models";

export type ExtendedPartnerItem = PartnerItem & { match: string };

export default async function getItemsFromReturns({
  partnerId,
  partnerUserLocation,
  recoveryMethodIds,
}: {
  partnerId: string;
  partnerUserLocation: PartnerLocationFragment;
  recoveryMethodIds: string[];
}): Promise<{ count: number; items: Array<ExtendedPartnerItem> } | null> {
  try {
    const returns = await getReturns({
      partnerId,
      filters: {
        limit: 3,
        status: ReturnStatus.PENDING,
        recovery_method: recoveryMethodIds,
        ...(partnerUserLocation?.id && {
          lost_location: [partnerUserLocation.id],
        }),
      },
    });

    if (returns === null) return { count: 0, items: [] };

    const matches = await Promise.all(
      returns.results.map(async ({ match }) => {
        return await getMatchFromId({ partnerId, matchId: match });
      }),
    );

    const items = await Promise.all(
      matches.map(async match => {
        if (match === null || match === undefined) return;

        return {
          ...(await getPartnerItemFromId({ partnerId, itemId: match.item })),
          match: match.id,
        };
      }),
    );

    return {
      count: returns.count,
      items: items.filter(i => i !== null && i !== undefined) as Array<ExtendedPartnerItem>,
    };
  } catch (e) {
    return null;
  }
}
