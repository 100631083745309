import { ItemStatus } from "client/types";
import objectToQueryString from "client/utils/objectToQueryString";

export { default as useCategoryCounts } from "./useCategoryCounts";
export { default as useStatusCounts } from "./useStatusCounts";
export { default as useStorageLocationCounts } from "./useStorageLocationCounts";
export { default as useUnclaimedStatusCounts } from "./useUnclaimedStatusCounts";

type OptionalArray<T> = T | T[];

export type CountQueryFilters = {
  lost_locations: OptionalArray<string>;
  category: OptionalArray<string>;
  storageLocation: OptionalArray<string>;
  date_before: Date | null;
  date_after: Date | null;
  status: OptionalArray<string>;
  unclaimed_status: OptionalArray<string>;
  suggested_unclaimed_status: OptionalArray<string>;
};

export type OptionalCountQueryFilters = Partial<CountQueryFilters>;

const defaultFilters: Readonly<OptionalCountQueryFilters> = {
  status: [ItemStatus.HAS_MATCHES, ItemStatus.NO_MATCHES, ItemStatus.MATCHED],
};

const nameMappings = {
  date_before: "collected_at_before",
  date_after: "collected_at_after",
  storageLocation: "storage_locations",
};

const valueFormatter: Record<string, (value: unknown) => unknown> = {};

export function formatFilters(queryObject: OptionalCountQueryFilters) {
  const queryCopy = { ...defaultFilters };

  for (const [key, value] of Object.entries(queryObject)) {
    if (!value) continue;
    if (value === "ALL") continue;
    const formatter = valueFormatter[key as keyof CountQueryFilters];
    const formattedValue = formatter ? formatter(value) : value;
    if (key in nameMappings) {
      queryCopy[nameMappings[key]] = formattedValue;
    } else {
      queryCopy[key] = formattedValue;
    }
  }

  return objectToQueryString(queryCopy).toString();
}
