import { InTransit, PickupReady } from "@assets/icons/icons";
import { useUserUpdateReturn } from "client/actions/userReturns";
import { ClaimDetailsContext } from "client/context/ClaimDetails";
import useRecoveryMethods from "client/hooks/data/user/useRecoveryMethods";
import { RecoveryMethodsEnum } from "client/types";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { isLgQuery } from "../../../../../helpers/mediaQuery";
import { Button, Text } from "../../../../../ui-components";

export default function AwaitingPickupActionBar() {
  const isLg = isLgQuery();
  const recoveryMethods = useRecoveryMethods();
  const { claim, match, returnResponse, shipment, partnerRecoveryMethods } =
    useContext(ClaimDetailsContext);
  const { mutate, data, isPending } = useUserUpdateReturn();
  const hasShippingOption = partnerRecoveryMethods?.some(mode =>
    [
      RecoveryMethodsEnum.SHIPPING_COST_COVERED,
      RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED,
    ].includes(mode),
  );
  const changeToShipping = async () => {
    if (!returnResponse) return;
    await mutate({
      returnId: returnResponse?.id,
      recovery_method:
        recoveryMethods.data?.find(
          ({ value }) => RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED === value,
        )?.id || "",
    });
  };

  if (!claim || !match || !hasShippingOption) {
    return null;
  }

  if (
    data &&
    recoveryMethods.data?.find(method => method.value !== RecoveryMethodsEnum.LOCAL_PICKUP)?.id ===
      data?.recovery_method
  ) {
    return (
      <Redirect
        to={`/shippingcheckout?return_object=${returnResponse?.id || ""}&claim_id=${claim.id}${
          shipment?.id ? `&shipment_id=${shipment?.id}` : ""
        }`}
      />
    );
  }

  return (
    <div className="d-flex flex-row justify-content-between flex-grow-1">
      <div className="d-none d-lg-flex flex-row gap-25 align-items-center" data-testid="pickupText">
        <PickupReady
          height={48}
          width={48}
          titleId="ready-for-pickup-icon"
          accessibilityTitle="Ready for pickup icon"
        />
        <Text fontWeight={700}>Ready for pickup!</Text>
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-lg-end flex-grow-1 gap-3">
        <div
          className="d-flex justify-content-center align-items-center gap-2"
          data-testid="switchRecoveryMethodText"
        >
          <InTransit accessibilityTitle="in-transit icon" titleId="inTransitIcon" />
          <Text fontWeight={700}>Want your item delivered?</Text>
        </div>
        <Button
          aria-label="claim options"
          data-testid="shipMyItemButton"
          className="flex-shrink-0"
          onClick={changeToShipping}
          size="2xl"
          fullWidth={!isLg}
          loading={isPending}
        >
          Ship my item
        </Button>
      </div>
    </div>
  );
}
