import { ClaimDetailsContext } from "client/context/ClaimDetails";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { ClaimStatus, RecoveryMethodsEnum, ShipmentStatus } from "../../../../types";
import AwaitingPaymentActionBar from "./AwaitingPaymentActionBar";
import AwaitingPickupActionBar from "./AwaitingPickupActionBar";
import MatchedActionBar from "./MatchedActionBar";
import NoMatchesActionBar from "./NoMatchesActionBar";
import { Container, Wrapper } from "./styles";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ActionBar({ className, ...props }: Props) {
  const { status, setPagePadding, returnRecoveryMethod } = useContext(ClaimDetailsContext);
  const ref = useRef<HTMLDivElement>(null);

  const content = useMemo<React.ReactNode>(() => {
    switch (status) {
      case ClaimStatus.NEW:
      case ClaimStatus.NO_MATCHES:
      case ClaimStatus.HAS_MATCHES:
        return <NoMatchesActionBar />;
      case ClaimStatus.MATCHED:
        return <MatchedActionBar />;
      case ShipmentStatus.AWAITING_PAYMENT:
        return <AwaitingPaymentActionBar />;
      case ShipmentStatus.PENDING:
        if (returnRecoveryMethod === RecoveryMethodsEnum.LOCAL_PICKUP) {
          return <AwaitingPickupActionBar />;
        }
        return null;
      default:
        return null;
    }
  }, [returnRecoveryMethod, status]);

  useEffect(() => {
    function callback() {
      if (ref.current !== null) {
        setPagePadding(ref.current.offsetHeight);
      } else {
        setPagePadding(0);
      }
    }

    callback();

    window.addEventListener("resize", callback, false);

    return () => window.removeEventListener("resize", callback, false);
  }, [ref, content, setPagePadding]);

  return content === null ? null : (
    <Wrapper ref={ref} {...props} className="px-35 pt-35 py-lg-3" data-testid="actionsBar">
      <Container className="d-flex flex-column flex-lg-row-reverse justify-content-lg-between gap-lg-5 align-items-lg-center mx-lg-auto">
        {content}
      </Container>
    </Wrapper>
  );
}
