import { useQuery } from "@tanstack/react-query";
import { PartnerLocation } from "client/types";
import useCurrentPartner from "client/utils/useCurrentPartner";
import queryFactory from "./queryFactory";
import usePartnerLocationStore from "./usePartnerLocationStore";

export default (partnerUserLocation?: PartnerLocation) => {
  const { partnerUserLocation: locationFromStore } = usePartnerLocationStore();
  let locationId = partnerUserLocation?.id;
  if (!locationId) {
    locationId = locationFromStore?.id;
  }

  const partnerId = useCurrentPartner();
  const { data, isLoading } = useQuery(queryFactory.storageLocations({ partnerId, locationId }));
  const { results: storageLocations, count } = data ?? {};

  return { storageLocations, count, isLoading };
};
