import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchApiData } from "client/utils/fetchApiData";
import { UserReturn, UserReturnUpdateRequest } from "../types";
import { GetUserReturnsRequest, ReturnsSearchResponse } from "../types/actions";
import getQueryString from "../utils/getQueryString";
import { getCommonHeaders } from "../utils/headers";

export const getUserReturns = (
  userReturnsReq: GetUserReturnsRequest,
): Promise<ReturnsSearchResponse> => {
  const queryString = userReturnsReq.filters ? getQueryString(userReturnsReq.filters) : "";
  return fetchApiData({ url: `/api/user/returns/${queryString}` });
};

export const getUserReturnById = async (userReturnId: string): Promise<UserReturn> => {
  return fetchApiData({ url: `/api/user/returns/${userReturnId}/` });
};

const updateUserReturn = (returnRequest: UserReturnUpdateRequest): Promise<UserReturn> => {
  return fetchApiData({
    url: `/api/user/returns/${returnRequest.returnId}/`,
    options: {
      method: "PATCH",
      headers: getCommonHeaders(),
      body: JSON.stringify({
        recovery_method: returnRequest.recovery_method,
      }),
    },
  });
};

export const useGetUserReturnById = (userReturnId?: string) => {
  return useQuery({
    queryKey: ["userReturns", userReturnId],
    queryFn: () => getUserReturnById(userReturnId ?? ""),
    enabled: !!userReturnId,
  });
};

export const useUserUpdateReturn = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: UserReturnUpdateRequest) => updateUserReturn(request),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["userReturns", variables.returnId],
      });
    },
    onError: error => console.error(error),
  });
};
