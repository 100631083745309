import {
  PartnerReturnsCountByRecoveryMethodRequest,
  PartnerReturnsCountByRecoveryMethodResponse,
} from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import objectToQueryString from "client/utils/objectToQueryString";

export default (
  request: PartnerReturnsCountByRecoveryMethodRequest,
): Promise<PartnerReturnsCountByRecoveryMethodResponse> => {
  const { partnerId, filters = {} } = request;
  const query = objectToQueryString(filters || {});
  const queryString = query.toString();

  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/returns/count-by-recovery-method/?${queryString}`,
  });
};
