import { getArchiveReturnsTabs } from "client/components/FilterTabs/constants";
import usePartner from "client/hooks/data/partner/usePartner";
import usePartnerRecoveryMethods from "client/hooks/data/partner/usePartnerRecoveryMethods";
import { Tabs } from "client/ui-components/Tabs";
import getUrlString from "client/utils/getUrlString";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React from "react";
import { useHistory } from "react-router-dom";
import useReturnTabs from "../../helpers/hooks/useReturnsTabs";
import { useReturnsStore } from "../../returnStore";
import ClearFilters from "../ClearFilters";
import { ReturnFilters } from "../ReturnsFilters";
import ToggleBulkOptions from "../ToggleBulkOptions";
import { Divider, TabsWrapper, ToolbarContainer, ToolbarSide } from "./styles";

export default function ReturnsToolbar() {
  const history = useHistory();
  const { partner } = usePartner(useCurrentPartner());
  const {
    filteredRecoveryMethods: { data: partnerRecoveryMethods },
  } = usePartnerRecoveryMethods(partner?.recovery_methods);
  const returnsArchiveTabs = getArchiveReturnsTabs(partnerRecoveryMethods);
  const { tabInfo, currentTab } = useReturnTabs();
  const { hasBulkOptions } = useReturnsStore();

  const rightAlignedLink = `${history.location.pathname}/archive?${getUrlString({
    recoveryMethod: returnsArchiveTabs[0].recoveryMethods,
    status: returnsArchiveTabs[0].statuses,
  })}&page=1`;
  return (
    <>
      <TabsWrapper>
        <Tabs
          tabs={tabInfo}
          endAlignedUrl={rightAlignedLink}
          endAlignedText="See completed returns"
          selectedTabIndex={currentTab}
          hasEndLink
          style={{ maxWidth: 1072 }}
        />
      </TabsWrapper>

      <ToolbarContainer>
        <ReturnFilters />
        <ToolbarSide>
          <ClearFilters />
          <ToggleBulkOptions
            style={{
              fontSize: 14,
              lineHeight: "20px",
              padding: 0,
              background: "transparent",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
            }}
          />
        </ToolbarSide>
      </ToolbarContainer>
      <Divider hasBulkOptions={hasBulkOptions} />
    </>
  );
}
