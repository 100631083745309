import { ColumnDef } from "@tanstack/react-table";
import { isLgQuery } from "client/helpers/mediaQuery";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import { ResultType } from "client/pages/PartnerGlobalSearch";
import DataTable from "client/ui-components/DataTable";
import { parseISO } from "date-fns";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import Loader from "../../../../../components/Loader";
import { useHasStorageLocations } from "../../../../../hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import { FormattedItem } from "../../../../../types";
import StatusBadge from "../../../../../ui-components/StatusBadge";
import { useInventoryStore } from "../../helpers/inventoryStore";
import MoveStorageLocationButton from "../MoveStorageLocation";
import NoItems from "../NoItems";
import ItemSnapshot from "./ItemSnapshot";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  items: FormattedItem[];
  isLoading: boolean;
  count: number;
  dataTestId?: string;
};

type styledColumnDef = (ColumnDef<FormattedItem> & {
  style?: React.CSSProperties;
})[];

const useItemTableColumns = () => {
  const hasStorageLocations = useHasStorageLocations();
  const isLg = isLgQuery();
  const dateFormatter = useDateFormatter();
  const columns: styledColumnDef = useMemo(() => {
    if (!isLg)
      return [
        {
          id: "singleColumn",
          header: "",
          cell: ({ row: { original } }) => (
            <ItemSnapshot
              categoryName={original.categoryName}
              images={original.images}
              snapshotKey={+original.id}
              key={original.id}
              name={original.name}
              label={original.inventoryId || ""}
              dateFound={dateFormatter(parseISO(String(original.date).split("T")[0]), "long")}
              storageLocation={original.storageLocation}
              status={original.status}
              isLg
            />
          ),
          style: {
            flexGrow: 1,
          },
        },
      ];
    return [
      {
        id: "item",
        header: "Item name & category",
        cell: ({ row: { original } }) => (
          <ItemSnapshot
            categoryName={original.categoryName}
            images={original.images}
            snapshotKey={+original.id}
            key={original.id}
            name={original.name}
            label={original.inventoryId ? `ID: ${original.inventoryId}` : ""}
            status={original.status}
            $showSingleImage
          />
        ),
        style: {
          width: hasStorageLocations ? "25%" : "30%",
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        cell: ({ row }) => (
          <div
            style={
              {
                lineClamp: 3,
                display: "-webkit-box",
                boxOrient: "vertical",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textAlign: "left",
              } as React.CSSProperties
            }
          >
            {row.getValue("description")}
          </div>
        ),
        style: {
          width: hasStorageLocations ? "25%" : "30%",
        },
      },
      ...(hasStorageLocations
        ? ([
            {
              accessorKey: "storageLocation",
              header: "Storage location",
              style: {
                minWidth: "15%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              },
            },
          ] as styledColumnDef)
        : []),
      {
        accessorKey: "date",
        header: "Date found",
        cell: ({ row }) => {
          const ISODate = row.getValue<string>("date").split("T")[0];
          const date = parseISO(ISODate);
          const formattedDate = dateFormatter(date, "long");

          return formattedDate;
        },
        style: {
          minWidth: "15%",
        },
      },
      {
        accessorKey: "status",
        header: () => <div style={{ textAlign: "right", paddingRight: 40 }}>Status</div>,
        cell: ({ row }) => (
          <div className="d-flex justify-content-end pe-45">
            <StatusBadge
              objectType={ResultType.ITEMS}
              objectStatus={row.getValue("status")}
              style={{
                fontFamily: "Lato",
              }}
            />
          </div>
        ),
      },
    ];
  }, [hasStorageLocations, isLg]);

  return columns;
};

export default function ItemsTable({ items, isLoading, count }: Props) {
  const history = useHistory();
  const { hasBulkOptions } = useInventoryStore();
  const hasStorageLocations = useHasStorageLocations();
  const columns = useItemTableColumns();
  const isMd = isLgQuery();

  if (isLoading) return <Loader />;
  if (items.length === 0) return <NoItems />;

  return (
    <DataTable
      data={items}
      columns={columns}
      hasBulkOptions={hasStorageLocations && hasBulkOptions}
      itemsCount={count}
      bulkActions={[MoveStorageLocationButton]}
      onRowClick={item => {
        history.push({
          pathname: `/partner/item/${item.id}`,
          state: {
            query: history.location.search,
          },
        });
      }}
      isList={!isMd}
    />
  );
}
