import { useSearchParam } from "client/hooks";
import {
  useCategoryCounts,
  useStatusCounts,
  useStorageLocationCounts,
} from "client/hooks/data/partner/partnerItemCounts";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import { ItemStatus } from "client/types";
import { stringToYellingCase } from "client/utils/stringUtils";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FilterOptions,
  initialCategoryFilter,
  initialStatusFilter,
  initialStorageLocationFilter,
  InventoryFilters,
  InventoryFilterValues,
} from "..";
import { FilterItem } from "../../../../../ui-components/FilterDropdown/types";
import { stringToDate } from "../../../../../utils/dateUtils";

const useFilters: () => {
  filters: InventoryFilters;
  setFilter: (type: FilterOptions, value: string) => void;
  statuses: FilterItem<ItemStatus | "ALL">[];
  categories: FilterItem[];
  storageLocations: FilterItem[];
  hasFilters: boolean;
  resetFilters: () => void;
  filterValues: InventoryFilterValues;
} = () => {
  const history = useHistory();
  const { partnerUserLocation } = usePartnerLocationStore();
  const [category, setCategory] = useSearchParam<string>("category", "ALL");
  const [status, setStatus] = useSearchParam<ItemStatus | "ALL">("status", "ALL");
  const [dateBefore, setDateBefore] = useSearchParam<string>("date_before", "ALL");
  const [dateAfter, setDateAfter] = useSearchParam<string>("date_after", "ALL");
  const [storageLocation, setStorageLocation] = useSearchParam<string>("storageLocation", "ALL");
  const [categoryValue, setCategoryValue] = useState<string>("ALL");
  const filterValues: InventoryFilterValues = useMemo(
    () => ({
      status: status || "ALL",
      category: categoryValue || "ALL",
      date_before: dateBefore === "ALL" ? null : stringToDate(dateBefore),
      date_after: dateAfter === "ALL" ? null : stringToDate(dateAfter),
      storageLocation: storageLocation || "ALL",
    }),
    [status, categoryValue, dateBefore, storageLocation, dateAfter],
  );
  const countFilters = useMemo(
    () => ({
      ...filterValues,
      lost_locations: partnerUserLocation?.id,
    }),
    [filterValues, partnerUserLocation],
  );

  const { statuses } = useStatusCounts(countFilters);
  const { categories } = useCategoryCounts(countFilters);
  const { storageLocations } = useStorageLocationCounts(countFilters);

  const selectedCategory = useMemo(
    () =>
      categories.find(item => stringToYellingCase(item.name) === category) || initialCategoryFilter,
    [categories, category],
  );
  const selectedStatus: FilterItem<ItemStatus | "ALL"> = useMemo(
    () => statuses.find(item => item.value === status) || initialStatusFilter,
    [statuses, status],
  );

  const selectedStorageLocation = useMemo(
    () =>
      storageLocations.find(item => item.value === storageLocation) || initialStorageLocationFilter,
    [storageLocations, storageLocation],
  );

  useEffect(() => {
    if (selectedCategory) {
      setCategoryValue(selectedCategory.value);
    }
  }, [selectedCategory]);

  function setFilter(type: FilterOptions, value: string) {
    switch (type) {
      case "status":
        setStatus(value as ItemStatus);
        break;
      case "category":
        setCategory(stringToYellingCase(value));
        break;
      case "date_before":
        setDateBefore(value);
        break;
      case "date_after":
        setDateAfter(value);
        break;
      case "storageLocation":
        setStorageLocation(value);
        break;
      default:
        break;
    }
  }

  const filters = useMemo(() => {
    return {
      status: selectedStatus,
      category: selectedCategory,
      date_before: dateBefore === "ALL" ? null : stringToDate(dateBefore),
      date_after: dateAfter === "ALL" ? null : stringToDate(dateAfter),
      storageLocation: selectedStorageLocation,
    };
  }, [selectedCategory, selectedStatus, dateBefore, selectedStorageLocation, dateAfter]);

  const hasFilters = useMemo(() => {
    return (
      filters.status.value !== "ALL" ||
      filters.category.value !== "ALL" ||
      filters.storageLocation.value !== "ALL" ||
      filters.date_before !== null ||
      filters.date_after !== null
    );
  }, [filters]);

  const resetFilters = () => {
    history.push({
      search: "",
    });
  };
  return {
    filters,
    setFilter,
    statuses,
    storageLocations,
    categories,
    hasFilters,
    resetFilters,
    filterValues,
  };
};

export default useFilters;
