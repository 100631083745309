import Cookies from "js-cookie";
import { LocalStorageConsts } from "../../types/localStorage";
import { getCommonHeaders } from "../../utils/headers";
import { AuthAction } from "./reducer";

export async function loginPartner(dispatch, loginPayload) {
  const requestOptions = {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: AuthAction.REQUEST_LOGIN });
    Cookies.remove("csrftoken", { path: "" });

    const response = await fetch("/api/partner/login/", requestOptions);
    const data = await response.json();
    if (data?.email) {
      const sessRes = await fetch("/api/partner/me/");
      const sess = await sessRes.json();
      if (sess?.id) {
        dispatch({ type: AuthAction.LOGIN_SUCCESS, payload: sess });
        localStorage.setItem(LocalStorageConsts.CURRENT_USER, JSON.stringify(sess));
        return sess;
      }
    }

    dispatch({
      type: AuthAction.LOGIN_ERROR,
      error: data?.detail,
    });
    return data;
  } catch (error) {
    dispatch({ type: AuthAction.LOGIN_ERROR, error: error });
  }
}

export function setCurrentUser(dispatch, user) {
  dispatch({ type: AuthAction.LOGIN_SUCCESS, payload: user });
  localStorage.setItem(LocalStorageConsts.CURRENT_USER, JSON.stringify(user));
}

export function updateCurrentPartner(dispatch, currentPartner) {
  localStorage.setItem(LocalStorageConsts.CURRENT_PARTNER, JSON.stringify(currentPartner));
  dispatch({ type: AuthAction.UPDATE_CURRENT_PARTNER, currentPartner });
}

export async function logout(dispatch, errorMessage?: string) {
  let user;
  try {
    dispatch && dispatch({ type: AuthAction.REQUEST_LOGOUT });
    user = JSON.parse(localStorage.getItem(LocalStorageConsts.CURRENT_USER) || "{}");

    await fetch(`/api/${user?.partners ? "partner" : "user"}/logout/`, {
      headers: getCommonHeaders(),
      method: "POST",
    }).then(() => {
      localStorage.removeItem(LocalStorageConsts.CURRENT_PARTNER);
      localStorage.removeItem(LocalStorageConsts.CURRENT_USER);

      Cookies.remove("csrftoken", { path: "" });
    });
  } catch (error) {
    dispatch && dispatch({ type: AuthAction.LOGOUT_ERROR, error: error });
    console.error(`Error in logoutUser: ${error}`);
  } finally {
    if (user?.partners?.length) {
      window.location.assign(
        "/partner/sign-in" + (errorMessage ? `?error=${encodeURIComponent(errorMessage)}` : ""),
      );
    } else {
      window.location.assign("/");
    }
  }
}
