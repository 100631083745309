import classNames from "classnames";
import { format } from "date-fns";
import React, { createElement, useContext, useEffect, useState } from "react";
import { ClaimDetailsContext } from "../../context/ClaimDetails";
import theme from "../../theme";
import { ClaimStatus, RecoveryMethodsEnum, ReturnStatus, ShipmentStatus } from "../../types";
import Heading from "../../ui-components/Heading";
import Text from "../../ui-components/Text";
import awaitingPaymentIcon from "./assets/awaitingPaymentIcon.svg";
import inTransit from "./assets/inTransit.svg";
import lookingForMatchesIcon from "./assets/lookingForMatches.svg";
import matchedIcon from "./assets/matchedIcon.svg";
import packagedIcon from "./assets/packagedIcon.svg";
import pickedUpIcon from "./assets/pickedUpIcon.svg";
import processingIcon from "./assets/processingIcon.svg";
import readyForPickupIcon from "./assets/readyForPickupIcon.svg";
import { ReadyForPickupAddressDisplay } from "./ReadyForPickupBanner";
import { Wrapper } from "./styles";
import TrackingDetails from "./TrackingDetails";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

type Details = {
  headerIcon?: React.ReactNode | null;
  title: string;
  description: React.ReactNode;
  additionalContent?: React.ReactNode;
  borderColor?: string;
};

const lookingForMatchDetails: Details = {
  headerIcon: (
    <img src={lookingForMatchesIcon} alt="looking for matches" width="48px" height="48px" />
  ),
  title: "We’re still looking",
  description: "We’re still looking for your item",
  borderColor: theme.ui.colors.primary500,
};

const matched: Details = {
  headerIcon: <img src={matchedIcon} alt="matched" width="48px" height="48px" />,
  title: "We found a match!",
  description: "Enter details to get your item back!",
  borderColor: theme.ui.colors.success500,
};

const awaitingPaymentDetails: Details = {
  headerIcon: <img src={awaitingPaymentIcon} alt="awaiting payment" width="48px" height="48px" />,
  title: "Payment details required",
  description: (
    <Text as="p" className="mb-0">
      Please enter your payment details to get your item back!
    </Text>
  ),
  borderColor: theme.ui.colors.error500,
};

const claimExpiredDetails: Details = {
  title: "Your claim has expired",
  description: (
    <Text className="mb-0">
      We are sorry you were not able to recover your item. For any inquiries, please contact{" "}
      <a href="mailto:help@thanksboomerang.com" style={{ fontWeight: 700 }}>
        help@thanksboomerang.com
      </a>
      .
    </Text>
  ),
};

const shipmentProcessingDetails: Details = {
  headerIcon: <img src={processingIcon} alt="processing" width="48px" height="48px" />,
  title: "Your shipment is being processed",
  description: "You will be notified when your item is packaged.",
  additionalContent: <TrackingDetails />,
  borderColor: theme.ui.colors.yellow500,
};

const shipmentPackagedDetails: Details = {
  headerIcon: <img src={packagedIcon} alt="packaged" width="36px" height="36px" className="m-15" />,
  title: "Your shipment is packaged",
  description: "You will be notified when your item is shipped!",
  additionalContent: <TrackingDetails />,
  borderColor: theme.ui.colors.yellow500,
};

const shipmentInTransitDetails: Details = {
  headerIcon: <img src={inTransit} alt="processing" width="48px" height="48px" />,
  title: "Your item is on its way!",
  description: "You will be notified when your item has been delivered.",
  additionalContent: <TrackingDetails />,
  borderColor: theme.ui.colors.yellow500,
};

const shipmentDeliveredDetails: Details = {
  headerIcon: <img src={inTransit} alt="processing" width="48px" height="48px" />,
  title: "Delivered",
  description: "Your item has been delivered!",
  additionalContent: <TrackingDetails />,
  borderColor: theme.ui.colors.secondary500,
};

const readyForPickupDetails: Details = {
  headerIcon: <img src={readyForPickupIcon} alt="ready for pickup" width="48px" height="48px" />,
  title: "Pickup instructions",
  description: createElement(function () {
    const { match } = useContext(ClaimDetailsContext);

    return match === null ? null : <>{match.pickup_notes}</>;
  }),
  additionalContent: <ReadyForPickupAddressDisplay />,
  borderColor: theme.ui.colors.secondary500,
};

const pickedUpDetails: Details = {
  headerIcon: <img src={pickedUpIcon} alt="picked up" width="48px" height="48px" />,
  title: "Picked up!",
  description: <Text className="mb-0">Item has been picked up!</Text>,
  additionalContent: createElement(function () {
    const { returnResponse } = useContext(ClaimDetailsContext);

    return returnResponse === null || returnResponse === undefined ? null : (
      <Text as="p" className="mb-0" fontSize="14px" lineHeight="20px">
        Picked up{" "}
        <Text as="span" fontSize="14px" lineHeight="20px" fontWeight="700">
          {format(new Date(returnResponse.returned_at), "eee, LLL d yyyy")}
        </Text>
      </Text>
    );
  }),
  borderColor: theme.ui.colors.secondary500,
};

function hydrateContent(status, returnRecoveryMethod): Details | null {
  switch (status) {
    case ClaimStatus.NEW:
    case ClaimStatus.NO_MATCHES:
    case ClaimStatus.HAS_MATCHES:
      return lookingForMatchDetails;
    case ClaimStatus.MATCHED:
      return matched;
    case ClaimStatus.EXPIRED:
      return claimExpiredDetails;
    case ShipmentStatus.AWAITING_PAYMENT:
      return awaitingPaymentDetails;
    case ReturnStatus.COMPLETED:
      return pickedUpDetails;
    case ShipmentStatus.DELIVERED:
      return shipmentDeliveredDetails;
    case ShipmentStatus.SHIPPED:
      return shipmentInTransitDetails;
    case ShipmentStatus.PACKAGED:
      return shipmentPackagedDetails;
    case ReturnStatus.PENDING:
    case ShipmentStatus.PENDING:
      switch (returnRecoveryMethod) {
        case RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED:
          return shipmentProcessingDetails;
        case RecoveryMethodsEnum.LOCAL_PICKUP:
          return readyForPickupDetails;
        default:
          return null;
      }
    default:
      return null;
  }
}

export default function ReturnStatusBanner({ className, ...props }: Props) {
  const { status, returnRecoveryMethod } = useContext(ClaimDetailsContext);
  const [content, setContent] = useState<Details | null>(null);

  useEffect(() => {
    setContent(hydrateContent(status, returnRecoveryMethod));
  }, [status, returnRecoveryMethod]);

  return content === null ? null : (
    <Wrapper
      {...props}
      borderColor={content.borderColor}
      className={classNames(
        "flex-grow-1 p-35 px-md-45 d-flex flex-column gap-25 mw-100",
        className,
      )}
    >
      <div className="d-flex flex-row gap-25">
        {content.headerIcon !== undefined && content.headerIcon}

        <div className="d-flex flex-column">
          <Heading as="h3" variant="h4" size="xs" className="mb-0 fw-bold fs16">
            {content.title}
          </Heading>

          <Text>{content.description}</Text>
        </div>
      </div>

      {content.additionalContent !== undefined && content.additionalContent}
    </Wrapper>
  );
}
