import { useQueryClient } from "@tanstack/react-query";
import queryFactory from "client/hooks/data/partner/queryFactory";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerRecoveryMethods from "client/hooks/data/partner/usePartnerRecoveryMethods";
import useCategories from "client/hooks/data/user/useCategories";
import { RecoveryMethodsEnum, ReturnStatus } from "client/types";
import Typography from "client/ui-components/Typography";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetPartnerClaimFromId } from "../../../../actions/partnerClaims";
import { useGetPartnerItemFromId } from "../../../../actions/partnerItems";
import { useGetMatchFromId } from "../../../../actions/partnerMatches";
import {
  updatePartnerReturn,
  useCompletePartnerReturn,
  useGetPartnerReturnById,
} from "../../../../actions/partnerReturns";
import { useGetPartnerShipmentById, useMarkAsPackaged } from "../../../../actions/partnerShipments";
import { TruckIcon } from "../../../../assets/icons/icons";
import ThreePanelLayout from "../../../../components/Layouts/ThreePanelLayout";
import Modal from "../../../../components/Modal";
import { ModalButtonTextEnum } from "../../../../components/Modal/Modal.types";
import { PartnerRoutesEnum } from "../../../../components/Routes";
import SingleViewHeader from "../../../../components/SingleViewHeader";
import { DateLocationVerbEnum } from "../../../../components/SingleViewHeader/index";
import SuccessModalInterior from "../../../../components/SuccessModalInterior";
import history from "../../../../context/history";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme";
import { mountBodyGray6, unmountBodyGray6 } from "../../../../utils/bodyStyle";
import useCurrentPartner from "../../../../utils/useCurrentPartner";
import PickupConfirmationInterior from "../PickupConfirmationInterior";
import SingleReturnButtons from "./SingleReturnButtons";
import SingleReturnLeftPanel from "./SingleReturnLeftPanel";
import SingleReturnRightPanel from "./SingleReturnRightPanel";

const SingleReturnView: React.FC = () => {
  const isLg = isLgQuery();
  const partnerId = useCurrentPartner();
  const { partnerLocations } = usePartnerLocations(partnerId);
  const { allRecoveryMethods } = usePartnerRecoveryMethods();
  const categories = useCategories();
  const { matchId } = useParams<{ matchId: string }>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);

  const [getMatchFromId, { data: matchDataResp }] = useGetMatchFromId();

  const [getPartnerClaimFromId, { data: partnerClaimDataResp }] = useGetPartnerClaimFromId();

  const [getPartnerItemFromId, { data: partnerItemDataResp }] = useGetPartnerItemFromId();

  const [getPartnerReturnById, { data: partnerReturnDataResp }] = useGetPartnerReturnById();

  const [getPartnerShipmentById, { data: partnerShipmentDataResp, loading: shipmentLoading }] =
    useGetPartnerShipmentById();

  const [
    completeReturn,
    { data: completeReturnData, loading: completeReturnLoading, error: completeReturnError },
  ] = useCompletePartnerReturn();

  const [completeAwaitingPaymentReturn, { loading: completeAwaitingPaymentReturnLoading }] =
    useCompletePartnerReturn();

  const [
    markAsPackaged,
    { data: markAsPackagedData, loading: markAsPackagedLoading, error: markAsPackagedError },
  ] = useMarkAsPackaged();

  const itemLocation = partnerLocations?.find(
    location => location.id === partnerItemDataResp?.lost_location,
  );

  const isPickup =
    allRecoveryMethods.localPickupMethod?.id === partnerReturnDataResp?.recovery_method;
  const isAwaitingPayment =
    partnerReturnDataResp?.status === ReturnStatus.PENDING &&
    !(partnerShipmentDataResp?.transactions ?? []).find(
      transaction => transaction.status === "SUCCESS",
    );

  const queryClient = useQueryClient();
  const {
    allRecoveryMethods: { localPickupMethod, shippingMethods },
  } = usePartnerRecoveryMethods();
  const localPickupId = localPickupMethod?.id;
  const shippingId = shippingMethods?.find(
    method => method.value === RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED,
  )?.id;

  const confirmationModalContent = isPickup
    ? {
        header: "Complete pickup?",
        subHeader: "Please make sure to check their ID for verification",
        topBtnOnClick: () => {
          completeReturn(partnerId, partnerReturnDataResp!.id);
          setShowConfirmationModal(false);
        },
        topBtnText: ModalButtonTextEnum.YES_COMPLETE_PICKUP,
      }
    : isAwaitingPayment
      ? {
          header: "Complete pickup",
          subHeader: "Almost there! Please review item & contact details.",
          topBtnOnClick: async () => {
            try {
              await updatePartnerReturn({
                partnerId: partnerId,
                id: partnerReturnDataResp!.id,
                recoveryMethod: localPickupId ?? "",
              });
              await completeAwaitingPaymentReturn(partnerId, partnerReturnDataResp!.id);
              queryClient.invalidateQueries({
                queryKey: queryFactory.returns(partnerId),
                refetchType: "all",
              });
              getMatchFromId({
                partnerId: partnerId,
                matchId: matchId,
              });
              toast.success("Return marked as picked up.");
            } catch (error) {
              toast.error("Item could not be marked as picked up. Please contact support.");
            } finally {
              setShowConfirmationModal(false);
            }
          },
          topBtnText: ModalButtonTextEnum.YES_COMPLETE_PICKUP,
          bottomBtnText: ModalButtonTextEnum.BACK,
        }
      : {
          header: "Mark as packaged?",
          icon: (
            <TruckIcon
              className="mx-auto"
              color={theme.colors.primaryBlue}
              height={54}
              width={63}
              accessibilityTitle="Truck icon"
              titleId="Truck icon"
            />
          ),
          subHeader:
            "Performing this action will manually mark the item as packaged. The guest will be notified of the updated status via email.",
          topBtnOnClick: () => {
            markAsPackaged({
              partnerId: partnerId,
              shipmentId: partnerReturnDataResp!.shipment,
            });
            setShowConfirmationModal(false);
          },
          topBtnText: ModalButtonTextEnum.MARK_AS_PACKAGED,
          maxWidth: "386px",
        };

  const successModalContent = isPickup
    ? {
        description: "You've successfully returned an item!",
        header: "Item Returned!",
      }
    : {
        description: "You've successfully marked the item as packaged!",
        header: "Item marked as packaged!",
      };

  const switchModalContent = isPickup
    ? {
        header: "Switch to Shipping?",
        description: "Are you sure you want to switch the return method from pickup to shipping?",
        topBtnOnClick: async () => {
          try {
            await updatePartnerReturn({
              partnerId: partnerId,
              id: partnerReturnDataResp!.id,
              recoveryMethod: shippingId ?? "",
            });
            toast.success("Return method switched to shipping successfully.");
            getMatchFromId({
              partnerId,
              matchId,
            });
            queryClient.invalidateQueries({
              queryKey: queryFactory.returns(partnerId),
              refetchType: "all",
            });
          } catch (error) {
            toast.error("Return method could not be updated. Please contact Support.");
          } finally {
            setShowSwitchModal(false);
          }
        },
      }
    : {
        header: "Switch to Pickup?",
        description: "Are you sure you want to switch the return method from shipping to pickup?",
        topBtnOnClick: async () => {
          try {
            await updatePartnerReturn({
              partnerId: partnerId,
              id: partnerReturnDataResp!.id,
              recoveryMethod: localPickupId ?? "",
            });
            queryClient.invalidateQueries({
              queryKey: queryFactory.returns(partnerId),
              refetchType: "all",
            });
            getMatchFromId({
              partnerId: partnerId,
              matchId: matchId,
            });
            toast.success("Return method switched to pickup successfully.");
          } catch (error) {
            toast.error("Return method could not be updated. Please contact Support.");
          } finally {
            setShowSwitchModal(false);
          }
        },
      };

  useEffect(() => {
    if (isLg) {
      mountBodyGray6();
      return function cleanup() {
        unmountBodyGray6();
      };
    }
  }, [isLg]);

  useEffect(() => {
    getMatchFromId({
      partnerId: partnerId,
      matchId: matchId,
    });
  }, [matchId]);

  useEffect(() => {
    if (!matchDataResp) return;
    getPartnerClaimFromId({
      partnerId: partnerId,
      claimId: matchDataResp.claim,
    });
    getPartnerItemFromId({
      partnerId: partnerId,
      itemId: matchDataResp.item,
    });
    getPartnerReturnById({
      partnerId: partnerId,
      returnId: matchDataResp.return,
    });
  }, [matchDataResp]);

  useEffect(() => {
    if (!partnerReturnDataResp?.shipment) return;
    getPartnerShipmentById({
      partnerId: partnerId,
      shipmentId: partnerReturnDataResp.shipment,
    });
  }, [partnerReturnDataResp]);

  useEffect(() => {
    if (completeReturnData || markAsPackagedData) {
      setShowSuccessModal(true);
    }
  }, [completeReturnData, markAsPackagedData]);

  useEffect(() => {
    if (!markAsPackagedError) return;
    setShowConfirmationModal(true);
    toast.error("Error completing shipment. Please try again.");
  }, [markAsPackagedError]);

  useEffect(() => {
    if (!completeReturnError) return;
    setShowConfirmationModal(true);
    toast.error("Error completing return. Please try again.");
  }, [completeReturnError]);

  return (
    <>
      <ThreePanelLayout
        TopPanel={
          partnerItemDataResp &&
          itemLocation && (
            <SingleViewHeader
              artifactData={partnerItemDataResp}
              artifactLocation={itemLocation}
              buttons={
                <SingleReturnButtons
                  setShowConfirmationModal={setShowConfirmationModal}
                  setShowSwitchModal={setShowSwitchModal}
                  returnData={partnerReturnDataResp}
                  shipmentData={partnerShipmentDataResp}
                />
              }
              dateLostFound={partnerItemDataResp?.collected_at}
              pageDescription="Return details"
              pageTitle="Returns"
              dateLocationVerb={DateLocationVerbEnum.FOUND}
            />
          )
        }
        LeftPanel={partnerItemDataResp && <SingleReturnLeftPanel itemData={partnerItemDataResp} />}
        RightPanel={
          !shipmentLoading &&
          partnerItemDataResp &&
          partnerClaimDataResp &&
          partnerReturnDataResp && (
            <SingleReturnRightPanel
              itemData={partnerItemDataResp}
              claimData={partnerClaimDataResp}
              returnData={partnerReturnDataResp}
              categories={categories.data ?? []}
              setShowConfirmationModal={setShowConfirmationModal}
              shipmentData={partnerShipmentDataResp}
              setShowSwitchModal={setShowSwitchModal}
            />
          )
        }
        leftPanelWidth="420px"
      />
      {categories.data && partnerItemDataResp && partnerReturnDataResp && (
        <Modal
          bottomBtnOnClick={() => {
            setShowConfirmationModal(false);
          }}
          children={
            (isPickup || isAwaitingPayment) && (
              <PickupConfirmationInterior
                artifact={partnerItemDataResp}
                categories={categories.data}
                contactDetails={partnerClaimDataResp?.user}
              />
            )
          }
          header={confirmationModalContent.header}
          maxWidth={confirmationModalContent.maxWidth}
          icon={confirmationModalContent.icon}
          show={
            showConfirmationModal ||
            completeReturnLoading ||
            markAsPackagedLoading ||
            completeAwaitingPaymentReturnLoading
          }
          showXButton
          subHeader={confirmationModalContent.subHeader}
          topBtnOnClick={confirmationModalContent.topBtnOnClick}
          topBtnLoading={completeReturnLoading}
          topBtnText={confirmationModalContent.topBtnText}
          bottomBtnText={confirmationModalContent.bottomBtnText ?? ModalButtonTextEnum.CANCEL}
        />
      )}
      <Modal
        bottomBtnText={ModalButtonTextEnum.GO_TO_RETURNS}
        bottomBtnOnClick={() => {
          setShowSuccessModal(false);
          history.push(PartnerRoutesEnum.RETURNS);
        }}
        showXButton
        onXButton={() => {
          history.push(PartnerRoutesEnum.RETURNS);
        }}
        children={
          <SuccessModalInterior
            header={successModalContent.header}
            description={
              <Typography
                className="text-center"
                data-testid="successModalDescription"
                as="p"
                fontSize={theme.ui.fontSizes.base}
                color={theme.ui.colors.primary700}
                fontWeight={theme.ui.fontWeights.normal}
                lineHeight={1.75}
              >
                {successModalContent.description}
              </Typography>
            }
          />
        }
        show={showSuccessModal}
      />
      <Modal
        header={switchModalContent.header}
        subHeader={switchModalContent.description}
        topBtnText={ModalButtonTextEnum.CONTINUE}
        topBtnOnClick={switchModalContent.topBtnOnClick}
        bottomBtnText={ModalButtonTextEnum.CANCEL}
        bottomBtnOnClick={() => {
          setShowSwitchModal(false);
        }}
        showXButton
        onXButton={() => {
          setShowSwitchModal(false);
        }}
        show={showSwitchModal}
        children={null}
      />
    </>
  );
};

export default SingleReturnView;
