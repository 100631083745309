import useCategories from "client/hooks/data/user/useCategories";
import useClaim from "client/hooks/data/user/useClaim";
import useRecoveryMethods from "client/hooks/data/user/useRecoveryMethods";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCompleteTransaction, useGetShipment } from "../../actions/shippingCheckout";
import { useGetUserItemFromId } from "../../actions/userItems";
import { useGetUserMatchFromId } from "../../actions/userMatches";
import { useGetUserReturnById } from "../../actions/userReturns";
import { updateUserCurrentReturn } from "../../context/User/actions";
import { useUserState } from "../../hooks/useUserState";
import {
  Claim,
  ClaimStatus,
  ImageSearchResponse,
  RecoveryMethod,
  RecoveryMethodsEnum,
  ReturnStatus,
  ShipmentResponse,
  ShipmentStatus,
  UserItem,
  UserMatchFromIdResponse,
  UserReturn,
} from "../../types";
import { getCategoryName } from "../../utils/mappers";

/* TODO: Restructure status */
// type LocalStatus = "N0_MATCHES" | "MATCHED" | "READY_FOR_PICKUP" | "AWAITING_PAYMENT" | "PROCESSING" | "IN_TRANSIT" | "DELIVERED" | "PICKED_UP";

type ClaimDetailsContextType = {
  claim?: Claim | null;
  claimResLoading: boolean;
  isLoading: boolean;
  item?: UserItem | null;
  itemImages: ImageSearchResponse | null;
  itemWithCategoryName?: UserItem & { category: string };
  match: UserMatchFromIdResponse | null;
  matchComplete: boolean;
  matchFound: boolean;
  pagePadding: number;
  partnerDefaultRecoveryMethod?: RecoveryMethodsEnum;
  setPagePadding: React.Dispatch<React.SetStateAction<ClaimDetailsContextType["pagePadding"]>>;
  partnerRecoveryMethods?: RecoveryMethodsEnum[];
  returnRecoveryMethod?: RecoveryMethodsEnum;
  returnResponse?: UserReturn | null;
  shipment: ShipmentResponse | null;
  status: ReturnStatus | ShipmentStatus | ClaimStatus | undefined;
};

export const ClaimDetailsContext = createContext<ClaimDetailsContextType>({
  claimResLoading: true,
  isLoading: true,
  itemImages: null,
  match: null,
  matchComplete: false,
  matchFound: false,
  pagePadding: 0,
  returnResponse: null,
  shipment: null,
  status: undefined,
  setPagePadding: () => undefined,
});

interface urlParams {
  claimId: string;
}

interface searchParams {
  rateId?: string;
  success?: string;
  transactionId?: string;
}

export default function ClaimDetailsProvider({ children }: { children: React.ReactNode }) {
  const [status, setStatus] = useState<ClaimDetailsContextType["status"] | undefined>();
  const { claimId } = useParams<urlParams>();
  const { search } = useLocation<searchParams>();
  const [userState, userDispatch] = useUserState();
  const [pagePadding, setPagePadding] = useState<ClaimDetailsContextType["pagePadding"]>(0);

  const { claim, isLoading: claimResLoading } = useClaim(claimId);

  const [getItemFromId, { data: item, loading: itemLoading }] = useGetUserItemFromId();
  const [getMatchById, { data: matchRes, loading: matchLoading }] = useGetUserMatchFromId();
  const { data: returnRes, isLoading: returnLoading } = useGetUserReturnById(matchRes?.return);
  const [completeTransaction, { data: shipComplete, loading: shipLoading }] =
    useCompleteTransaction();
  const [getShipment, { data: shipment, loading: shipmentLoading }] = useGetShipment();
  const { data: categories, isLoading: isCategoriesLoading } = useCategories();
  const { data: recoveryMethods, isLoading: isRecoveryMethodsLoading } = useRecoveryMethods();

  const isLoading = useMemo(() => {
    return (
      claimResLoading ||
      itemLoading ||
      matchLoading ||
      returnLoading ||
      shipLoading ||
      shipmentLoading ||
      isCategoriesLoading ||
      isRecoveryMethodsLoading
    );
  }, [
    claimResLoading,
    itemLoading,
    matchLoading,
    returnLoading,
    shipLoading,
    shipmentLoading,
    isCategoriesLoading,
    isRecoveryMethodsLoading,
  ]);

  const matchFound = useMemo(() => claim?.status === ClaimStatus.MATCHED, [claim]);

  const matchComplete = useMemo(() => claim?.status === ClaimStatus.COMPLETED, [claim]);

  const completeTransactionStep = useMemo<boolean>(() => {
    const query = new URLSearchParams(search);
    return query.get("success") === "true";
  }, [search]);

  const isCheckoutSuccessful = useMemo(() => {
    if (
      returnRes === undefined ||
      returnRes === null ||
      returnRes.shipment === null ||
      shipment === null
    )
      return false;

    return (
      (shipment.transactions || []).map(({ status }) => status).filter(s => s === "SUCCESS")
        .length > 0
    );
  }, [returnRes, shipment]);

  useEffect(() => {
    if (shipment === null || !returnRes?.shipment) return;

    if (completeTransactionStep && !shipComplete && !isCheckoutSuccessful) {
      const query = new URLSearchParams(search);
      const transactionId = query.get("transactionId");
      const rateId = query.get("rateId");

      if (transactionId && rateId) {
        completeTransaction({
          transactionId,
          rate: {
            rate_id: rateId,
          },
        }).then(() => getShipment(returnRes?.shipment));
      }
    }
  }, [
    completeTransactionStep,
    shipComplete,
    search,
    isCheckoutSuccessful,
    shipment,
    returnRes?.shipment,
  ]);

  useEffect(() => {
    if (!claim) return;
    if (matchFound || matchComplete) {
      claim.match && getMatchById(claim.match);
    }
  }, [claim, matchFound, matchComplete]);

  useEffect(() => {
    if (!claim) return;
    if (!matchRes) return;
    if (matchFound || matchComplete) {
      getItemFromId({ id: matchRes.item });
    }
  }, [matchRes]);

  useEffect(() => {
    if (!returnRes) return;
    updateUserCurrentReturn(userDispatch, returnRes);
  }, [returnRes]);

  useEffect(() => {
    if (!returnRes?.shipment) return;
    getShipment(returnRes?.shipment);
  }, [returnRes]);

  const itemImages: ImageSearchResponse | null = item && {
    count: item.images.length,
    results: item.images,
  };

  const itemWithCategoryName = useMemo<ClaimDetailsContextType["itemWithCategoryName"]>(() => {
    if (item === null || categories === undefined) return undefined;
    return {
      ...item,
      category: getCategoryName(categories, item.category),
    };
  }, [item, categories]);

  const partnerRecoveryMethods = useMemo<RecoveryMethodsEnum[] | undefined>(() => {
    if (!claim || !recoveryMethods) return undefined;
    return (
      recoveryMethods.filter(r =>
        claim.lost_location.partner.recovery_methods.includes(r.id),
      ) as RecoveryMethod[]
    )?.map(r => r.value);
  }, [claim, recoveryMethods]);

  const partnerDefaultRecoveryMethod = useMemo<RecoveryMethodsEnum | undefined>(() => {
    if (!claim || !recoveryMethods) return undefined;

    return recoveryMethods?.find(r => r.id === claim.lost_location.partner.default_recovery_method)
      ?.value;
  }, [claim, recoveryMethods]);

  const returnRecoveryMethod = useMemo<RecoveryMethodsEnum | undefined>(
    () =>
      userState.currentReturn &&
      recoveryMethods?.find(
        recoveryMethod => recoveryMethod.id === userState.currentReturn?.recovery_method,
      )?.value,
    [userState, recoveryMethods],
  );

  useEffect(() => {
    setStatus(() => {
      const fallback = ClaimStatus.COMPLETED;

      switch (true) {
        /* Claim is not defined */
        case claim === undefined || claim === null:
          return fallback;

        /* Claim is not completed */
        case claim?.status !== ClaimStatus.COMPLETED:
          return ClaimStatus[(claim as Claim).status];

        /* Local pickup and return status is pending */
        case returnRes?.status === ReturnStatus.PENDING &&
          returnRecoveryMethod === RecoveryMethodsEnum.LOCAL_PICKUP:
          return ReturnStatus.PENDING;

        /* Not local pickup, return status is pending, checkout is not completed */
        case returnRes?.status === ReturnStatus.PENDING &&
          returnRecoveryMethod !== RecoveryMethodsEnum.LOCAL_PICKUP &&
          !isCheckoutSuccessful:
          return ShipmentStatus.AWAITING_PAYMENT;

        /* NOT local pickup, return status is pending, checkout is completed */
        case returnRecoveryMethod !== RecoveryMethodsEnum.LOCAL_PICKUP && isCheckoutSuccessful:
          return ShipmentStatus[(shipment as ShipmentResponse).status];

        /* Local pickup or not, return status is completed */
        case returnRes?.status === ReturnStatus.COMPLETED &&
          returnRecoveryMethod !== RecoveryMethodsEnum.LOCAL_PICKUP:
          return ReturnStatus.COMPLETED;

        default:
          return fallback;
      }
    });
  }, [returnRecoveryMethod, claim, returnRes, isCheckoutSuccessful, shipment]);

  const values = useMemo<ClaimDetailsContextType>(
    () => ({
      claim,
      claimResLoading,
      isLoading,
      item,
      itemImages,
      itemWithCategoryName,
      match: matchRes,
      matchComplete,
      matchFound,
      pagePadding,
      partnerDefaultRecoveryMethod,
      partnerRecoveryMethods,
      returnRecoveryMethod,
      returnResponse: returnRes,
      setPagePadding,
      shipment,
      status,
    }),
    [
      claim,
      claimResLoading,
      isLoading,
      item,
      itemImages,
      itemWithCategoryName,
      matchComplete,
      matchFound,
      matchRes,
      pagePadding,
      partnerDefaultRecoveryMethod,
      partnerRecoveryMethods,
      returnRecoveryMethod,
      returnRes,
      shipment,
      status,
    ],
  );

  return <ClaimDetailsContext.Provider value={values}>{children}</ClaimDetailsContext.Provider>;
}
