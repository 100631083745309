import { isLgQuery } from "client/helpers/mediaQuery";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerRecoveryMethods from "client/hooks/data/partner/usePartnerRecoveryMethods";
import useCategories from "client/hooks/data/user/useCategories";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import { ResultType } from "client/pages/PartnerGlobalSearch";
import {
  ItemStatus,
  PartnerClaim,
  PartnerItem,
  PartnerItemUnclaimedStatusEnum,
  PartnerLocation,
  RecoveryMethodsEnum,
  ReturnStatus,
  UnclaimedStatus,
} from "client/types";
import { StatusBadge, Text } from "client/ui-components";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { getCategoryName } from "client/utils/mappers";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React from "react";
import { PickupReady, ShippingReady } from "../../../../assets/icons/icons";
import PickupIconPlus from "../../../../assets/icons/pickup-icon-plus.svg";
import PickupIcon from "../../../../assets/icons/pickup-icon.svg";
import { useHasStorageLocations } from "../../../../hooks/data/partner/partnerItemCounts/useStorageLocationCounts";
import { getStorageLocationName } from "../../Inventory/helpers/hooks/useItems";
import DetailsCard from "./DetailsCard";
import {
  ContainerParagraph,
  ContainerPhotos,
  LocationFoundContainer,
  Paragraph,
  RightPanelContainer,
} from "./styles";
import TextDisplay from "./TextDisplay";

type Props = {
  partnerItemData: PartnerItem;
  artifactLocation: PartnerLocation;
  partnerClaimData?: PartnerClaim;
};

const StatusSubtext = ({
  status,
  partnerClaimData,
  partnerItemData,
  isLocalPickup,
}: {
  status: ItemStatus | UnclaimedStatus;
  partnerClaimData?: PartnerClaim;
  partnerItemData: PartnerItem;
  isLocalPickup: boolean;
}) => {
  const dateFormatter = useDateFormatter();
  switch (status) {
    case ItemStatus.HAS_MATCHES:
      return (
        <Paragraph className="mt-1">
          Don't forget to check out the potential match for this item.
        </Paragraph>
      );
    case ItemStatus.MATCHED:
      if (!partnerClaimData?.user?.email) return null;
      return (
        <Paragraph className="mt-1">
          An email has been sent to {partnerClaimData?.user?.email} requesting review of a potential
          match.
        </Paragraph>
      );
    case ItemStatus.COMPLETED:
      if (partnerItemData?.return_object) {
        if (partnerItemData.return_object.status === ReturnStatus.PENDING) {
          return <Paragraph className="mt-1">This item is in the return process.</Paragraph>;
        } else {
          if (partnerItemData.return_object.status === ReturnStatus.COMPLETED) {
            if (isLocalPickup) {
              return (
                <div className="d-flex gap-2 mt-1">
                  <PickupReady
                    accessibilityTitle={"ItemWasPickedUp"}
                    titleId={"item-picked-up-subtext"}
                  />
                  <Paragraph>
                    This item was picked up on{" "}
                    {dateFormatter(new Date(partnerItemData?.return_object.returned_at), "long")}.
                  </Paragraph>
                </div>
              );
            } else {
              return (
                <div className="d-flex gap-2 mt-1">
                  <ShippingReady
                    accessibilityTitle={"ItemWasShipped"}
                    titleId={"item-shipped-subtext"}
                  />
                  <Paragraph>
                    This item was shipped and delivered on{" "}
                    {dateFormatter(new Date(partnerItemData?.return_object.returned_at), "long")}.
                  </Paragraph>
                </div>
              );
            }
          }
        }
      }
      return null;
    case ItemStatus.UNCLAIMED:
      return (
        <Paragraph className="mt-1">
          This item has expired, but a final status has not been set.
        </Paragraph>
      );
    case UnclaimedStatus.DONATED:
    case UnclaimedStatus.DESTROYED:
    case UnclaimedStatus.SOLD:
    case UnclaimedStatus.STORED:
    case UnclaimedStatus.GIVEN_TO_STAFF:
    case UnclaimedStatus.RESTOCKED:
    case UnclaimedStatus.MORE_LIFE:
    case UnclaimedStatus.OTHER:
      if (!partnerItemData.unclaimed_status_set_at) return null;
      return (
        <Paragraph className="mt-1">
          {"This item was "}
          {status === UnclaimedStatus.OTHER
            ? "marked as Other"
            : status === UnclaimedStatus.MORE_LIFE
              ? "sold through Boomerang's More Life Program"
              : PartnerItemUnclaimedStatusEnum[status].toLowerCase()}
          {" on "}
          {dateFormatter(new Date(partnerItemData.unclaimed_status_set_at), "long")}.
        </Paragraph>
      );
    default:
      return null;
  }
};

export default function RightPanel({ partnerItemData, artifactLocation, partnerClaimData }: Props) {
  const { partnerLocations = [] } = usePartnerLocations(useCurrentPartner());
  const { allRecoveryMethods } = usePartnerRecoveryMethods();
  const categories = useCategories();
  const dateFormatter = useDateFormatter();
  const hasStorageLocations = useHasStorageLocations();
  const isLg = isLgQuery();
  const city = artifactLocation?.address?.city;
  const state = artifactLocation?.address?.region_code;
  const country = artifactLocation?.address?.country_code;

  const addressFirstLine = artifactLocation?.address?.address1;
  const addressSecondLine = `${city}, ${state} ${artifactLocation?.address?.postal_code}, ${country}`;

  const showLocation = partnerLocations?.length > 1;
  const objectStatus: ItemStatus | UnclaimedStatus =
    partnerItemData.status === ItemStatus.UNCLAIMED && partnerItemData.unclaimed_status
      ? partnerItemData.unclaimed_status
      : partnerItemData.status;

  const recoveryMethod = allRecoveryMethods.data.find(
    rm => rm.id === partnerItemData.return_object?.recovery_method,
  );
  const isLocalPickup = recoveryMethod?.value === RecoveryMethodsEnum.LOCAL_PICKUP;

  return (
    <RightPanelContainer className="print">
      <DetailsCard
        icon={PickupIcon}
        title="Basic info"
        style={{
          gap: isLg ? 28 : 20,
        }}
      >
        <TextDisplay title="Item status" className="no-print">
          <StatusBadge
            objectType={ResultType.ITEMS}
            objectStatus={objectStatus}
            returnObject={partnerItemData.return_object}
            style={{ width: "fit-content" }}
          />

          <StatusSubtext
            status={objectStatus}
            partnerClaimData={partnerClaimData}
            partnerItemData={partnerItemData}
            isLocalPickup={isLocalPickup}
          />
        </TextDisplay>

        <ContainerParagraph>
          <TextDisplay
            title="Date found"
            content={dateFormatter(
              convertToDateIgnoringTime(partnerItemData.collected_at),
              "short",
            )}
          />
          <TextDisplay
            title="Date logged"
            content={dateFormatter(new Date(partnerItemData.created_at), "short")}
          />
          <TextDisplay title="Logged by" content={partnerItemData.created_by.full_name || "-"} />
        </ContainerParagraph>

        {hasStorageLocations ? (
          <TextDisplay
            title="Storage location"
            content={getStorageLocationName({
              storageLocation: partnerItemData.storage_location,
              currentLocation: partnerItemData.current_location,
            })}
          />
        ) : null}

        {showLocation ? (
          <TextDisplay title="Location found">
            <LocationFoundContainer>
              <Text as="span" fontWeight={700}>
                {artifactLocation?.name}
              </Text>
              <Text as="span">{addressFirstLine}</Text>
              <Text as="span">{addressSecondLine}</Text>
            </LocationFoundContainer>
          </TextDisplay>
        ) : null}
      </DetailsCard>

      <DetailsCard icon={PickupIconPlus} title="Found item">
        <TextDisplay title="Name" content={partnerItemData.name} />
        <TextDisplay
          title="Category"
          content={getCategoryName(categories.data ?? [], partnerItemData.category)}
        />

        <TextDisplay
          data-testid="itemInventoryId"
          title="ID"
          content={partnerItemData.inventoryId || ""}
        />

        <TextDisplay title="Description" normal={true} content={partnerItemData.description} />

        {partnerItemData.images.length > 0 ? (
          <TextDisplay title="Photos" className="no-print">
            <ContainerPhotos>
              {partnerItemData.images.map(image => (
                <img src={image.image} alt="." />
              ))}
            </ContainerPhotos>
          </TextDisplay>
        ) : null}
      </DetailsCard>
    </RightPanelContainer>
  );
}
