import React from "react";
import Button from "../../Button";
import Link from "../../Link";
import { NavbarContent } from "./types";

const Partner: NavbarContent = {
  leftMenuItems: [
    // <Button ariaLabel="Case studies" variant="text" href="/">Case studies</Button>,
    // <Button ariaLabel="Pricing" variant="text" href="/">Pricing</Button>,
    <Button aria-label="About" variant="text" href="/about">
      About
    </Button>,
    <Button aria-label="blog" variant="text" href="https://thanksboomerang.com/blog">
      Our blog
    </Button>,
    <Button aria-label="blog" variant="text" className="primary-text" href="/">
      Lost something?
    </Button>,
  ],
  leftMenuItemsMobile: [
    <Link variant="gray" href="/about">
      About
    </Link>,
    <Link variant="gray" href="https://thanksboomerang.com/blog">
      Our blog
    </Link>,
    <Link variant="gray" href="/partner/sign-in">
      Sign in
    </Link>,
  ],
  rightMenuItemsMobile: [
    <Button
      aria-label="Lost something?"
      className="mb-275"
      size="2xl"
      fullWidth
      href="/"
      variant="outline"
    >
      Lost something?
    </Button>,
    <Button
      aria-label="Schedule a chat"
      size="2xl"
      fullWidth
      href="https://share.hsforms.com/1olnAZ_DYS8amUoozJO7uaAc60y2"
      rel="noopener noreferrer"
    >
      Schedule a chat
    </Button>,
  ],
  rightMenuItems: [
    <Button aria-label="Sign in" variant="text" className="me-25" href="/partner/sign-in">
      Sign in
    </Button>,
    <Button
      aria-label="Schedule a chat"
      href="https://share.hsforms.com/1olnAZ_DYS8amUoozJO7uaAc60y2"
      rel="noopener noreferrer"
    >
      Schedule a chat
    </Button>,
  ],
};

export default Partner;
