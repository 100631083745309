/* eslint-disable react/no-children-prop */
import React from "react";

// Route types
import { Route, Switch } from "react-router-dom";

import AnonRedirect from "./AnonRedirect";
import AnonRoute from "./AnonRoute";
import PrivatePartnerRoute from "./PrivatePartnerRoute";
import PrivateRoute from "./PrivateRoute";

// Views
import PortalView from "../scenes/views/PortalView";
import UserView from "../scenes/views/UserView";
import WelcomeView from "../scenes/views/WelcomeView";

// Public
import HomeNew from "../pages/Home";
import LostItemForm from "../pages/Lost/Partner/Location";
import Partner from "../pages/Partner";
import ShippingCosts from "../scenes/public/ShippingCosts";
import Support from "../scenes/public/Support";

// Partner
import ForgotPassword from "../scenes/auth/ForgotPassword";
import Welcome from "../scenes/auth/invite/Welcome";
import ResetPassword from "../scenes/auth/ResetPassword";
import Inventory from "../scenes/private/Inventory";
import ItemDetails from "../scenes/private/ItemDetails";
import AddItem from "../scenes/private/ItemForm/AddItem";
import EditItem from "../scenes/private/ItemForm/EditItem";
import Matches from "../scenes/private/Matches";
import Matching from "../scenes/private/Matches/Matching";
import Metrics from "../scenes/private/Metrics";
import Overview from "../scenes/private/Overview";
import Returns from "../scenes/private/Returns";

// Private / User
import PartnerGlobalSearch from "client/pages/PartnerGlobalSearch";
import AllResults from "client/pages/PartnerGlobalSearch/components/AllResults";
import ChangeToPickup from "client/scenes/private/ChangeToPickup";
import InventoryTabs from "client/scenes/private/Inventory/components/InventoryTabs";
import PickupConfirmed from "client/scenes/private/PickupConfirmed";
import ReturnsArchive from "client/scenes/private/Returns/ReturnsArchive";
import { UnclaimedInventory } from "client/scenes/private/UnclaimedInventory";
import UnclaimedSuggestion from "client/scenes/private/UnclaimedSuggestion";
import {
  FilterClaimProvider,
  LostItemCreationProvider,
  PartnerClaimProvider,
  PartnerSelectionProvider,
  SubLocationSelectionProvider,
  UserLoginContextProvider,
} from "../context";
import CancelClaimProvider from "../context/CancelClaim";
import ClaimDetailsProvider from "../context/ClaimDetails";
import OverviewContextProvider from "../context/Overview";
import PartnerGlobalSearchProvider from "../context/PartnerGlobalSearch";
import ShippingCheckoutProvider from "../context/ShippingCheckout";
import * as ClaimsSectionCopy from "../copy/userClaimsCopy";
import AboutPage from "../pages/About";
import ClaimEmailIntervalPage from "../pages/Claim/Notifications/index";
import CoBrandedWelcome from "../pages/CoBrandedWelcome";
import Login from "../pages/Login";
import PartnerSelection from "../pages/Lost";
import SubLocationSelection from "../pages/Lost/Partner";
import PartnerSignIn from "../pages/Partner/SignIn";
import Pricing from "../pages/Pricing/index";
import SignOutPage from "../pages/SignOut";
import AllClaims from "../scenes/private/AllClaims/AllClaims";
import { AllClaimsByStatus } from "../scenes/private/AllClaims/AllClaimsByStatus";
import CompletedReturns from "../scenes/private/AllClaims/CompletedReturns";
import ClaimDetails from "../scenes/private/ClaimDetails";
import CancelClaimForm from "../scenes/private/ClaimDetails/CancelClaim";
import Claims from "../scenes/private/Claims";
import ClaimsArchive from "../scenes/private/ClaimsArchive";
import ContactDetails from "../scenes/private/ContactDetails";
import InventoryArchive from "../scenes/private/InventoryArchive";
import PartnerSingleClaimView from "../scenes/private/PartnerSingleClaimView";
import SingleReturnView from "../scenes/private/Returns/PartnerReturnView";
import ShippingCheckout from "../scenes/private/ShippingCheckout";
import { EditClaim } from "../scenes/public/ClaimForm/EditClaim";

export enum PartnerRoutesEnum {
  ADD_ITEM = "/additem",
  CLAIM = "/partner/claim/:claimId",
  CLAIM_EDIT = "/partner/claim/:claimId/edit",
  CLAIMS = "/partner/claims",
  CLAIMS_ARCHIVE = "/partner/claims/archive",
  FORGOT_PASSWORD = "/partner/forgot-password",
  INVENTORY = "/partner/inventory",
  INVENTORY_ARCHIVE = "/partner/inventory/archive",
  INVENTORY_UNCLAIMED = "/partner/inventory/unclaimed",
  INVENTORY_UNCLAIMED_SUGGESTION = "/partner/inventory/unclaimed/:status",
  ITEM = "/partner/item/:itemId",
  ITEM_EDIT = "/partner/item/:itemId/edit",
  SIGN_IN = "/partner/sign-in",
  MATCHES = "/partner/matches",
  METRICS = "/partner/metrics",
  OVERVIEW = "/partner/overview",
  SINGLE_RETURN = "/partner/match/:matchId/return",
  RESET_PASSWORD = "/partner/resetpassword",
  RETURNS = "/partner/returns",
  WELCOME = "/partner/welcome",
  RETURNS_ARCHIVE = "/partner/returns/archive",
  SEARCH = "/partner/search/q",
  SEARCH_ALL = "/partner/search/all/q",
}

export enum UserRoutesEnum {
  CLAIM_DETAILS = "/claim/:claimId",
  CLAIM_DETAILS_EDIT = "/claim/:claimId/edit",
  CLAIM_DETAILS_CANCEL = "/claim/:claimId/cancel",
  CHANGE_TO_PICKUP = "/claim/:claimId/change-to-pickup",
  PICKUP_CONFIRMED = "/claim/:claimId/pickup-confirmed",
  CLAIMS = "/claims",
  CLAIMS_MATCHED = "/claims/matched",
  CLAIMS_RETURNS = "/claims/returns",
  CLAIMS_CANCELED = "/claims/canceled",
  CLAIMS_EXPIRED = "/claims/expired",
  COMPLETED_RETURNS = "/completed-returns",
  CLAIMS_STILL_LOOKING = "/claims/still-looking",
  CONTACT_DETAILS = "/contact-details",
  SHIPPING_CHECKOUT = "/shippingcheckout",
}

const Router = () => (
  <Switch>
    <AnonRoute path="/" exact children={<HomeNew />} />
    <Route path="/for-partners" exact children={<Partner />} />
    <Route path="/about" children={<AboutPage />} />
    <Route path="/lost/p/:urlPartnerSlug/l/:urlLocationSlug" children={<CoBrandedWelcome />} />

    <Route
      path="/lost/flow"
      children={
        <LostItemCreationProvider>
          <LostItemForm />
        </LostItemCreationProvider>
      }
    />

    <Route
      path="/lost/p/:urlPartnerSlug/select-location"
      children={
        <LostItemCreationProvider>
          <SubLocationSelectionProvider>
            <SubLocationSelection />
          </SubLocationSelectionProvider>
        </LostItemCreationProvider>
      }
    />
    {/* This has to come after the /selection-location one above */}
    <Route path="/lost/p/:urlPartnerSlug" children={<CoBrandedWelcome />} />

    <Route
      path="/lost"
      children={
        <LostItemCreationProvider>
          <PartnerSelectionProvider>
            <PartnerSelection />
          </PartnerSelectionProvider>
        </LostItemCreationProvider>
      }
    />

    <Route path="/pricing" children={<Pricing />} />
    <Route exact path="/sign-out" children={<SignOutPage />} />
    <PrivatePartnerRoute exact path={PartnerRoutesEnum.ADD_ITEM} children={<AddItem />} />

    <PrivateRoute exact path={UserRoutesEnum.CLAIMS} children={<AllClaims />} />

    <PrivateRoute exact path={UserRoutesEnum.COMPLETED_RETURNS} children={<CompletedReturns />} />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CLAIMS_STILL_LOOKING}
      children={
        <AllClaimsByStatus
          title={ClaimsSectionCopy.STILL_LOOKING.title}
          subheader={ClaimsSectionCopy.STILL_LOOKING.subheader}
        />
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CLAIMS_MATCHED}
      children={
        <AllClaimsByStatus
          title={ClaimsSectionCopy.CLAIMS_MATCHED.title}
          subheader={ClaimsSectionCopy.CLAIMS_MATCHED.subheader}
        />
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CLAIMS_RETURNS}
      children={
        <AllClaimsByStatus
          title={ClaimsSectionCopy.CLAIMS_RETURNS.title}
          subheader={ClaimsSectionCopy.CLAIMS_RETURNS.subheader}
        />
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CLAIMS_CANCELED}
      children={
        <AllClaimsByStatus
          title={ClaimsSectionCopy.CLAIMS_CANCELED.title}
          subheader={ClaimsSectionCopy.CLAIMS_CANCELED.subheader}
        />
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CLAIMS_EXPIRED}
      children={
        <AllClaimsByStatus
          title={ClaimsSectionCopy.CLAIMS_EXPIRED.title}
          subheader={ClaimsSectionCopy.CLAIMS_EXPIRED.subheader}
        />
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CONTACT_DETAILS}
      children={<UserView children={<ContactDetails />} heading="Contact details" />}
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.SHIPPING_CHECKOUT}
      children={
        <ShippingCheckoutProvider>
          <ShippingCheckout />
        </ShippingCheckoutProvider>
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CLAIM_DETAILS}
      children={
        <ClaimDetailsProvider>
          <ClaimDetails />
        </ClaimDetailsProvider>
      }
    />
    <PrivateRoute exact path={UserRoutesEnum.CLAIM_DETAILS_EDIT} children={<EditClaim />} />

    <PrivateRoute
      exact
      path={UserRoutesEnum.CLAIM_DETAILS_CANCEL}
      children={
        <ClaimDetailsProvider>
          <CancelClaimProvider>
            <CancelClaimForm />
          </CancelClaimProvider>
        </ClaimDetailsProvider>
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.CHANGE_TO_PICKUP}
      children={
        <ClaimDetailsProvider>
          <ChangeToPickup />
        </ClaimDetailsProvider>
      }
    />
    <PrivateRoute
      exact
      path={UserRoutesEnum.PICKUP_CONFIRMED}
      children={
        <ClaimDetailsProvider>
          <PickupConfirmed />
        </ClaimDetailsProvider>
      }
    />
    <PrivatePartnerRoute
      path={PartnerRoutesEnum.SEARCH}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView
            children={
              <OverviewContextProvider>
                <PartnerGlobalSearch />
              </OverviewContextProvider>
            }
            heading=""
          />
        </PartnerGlobalSearchProvider>
      }
    />

    <PrivatePartnerRoute
      path={PartnerRoutesEnum.SEARCH_ALL}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView
            children={
              <OverviewContextProvider>
                <AllResults />
              </OverviewContextProvider>
            }
            heading=""
          />
        </PartnerGlobalSearchProvider>
      }
    />

    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.OVERVIEW}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView
            isOverview={true}
            children={
              <OverviewContextProvider>
                <Overview />
              </OverviewContextProvider>
            }
            heading=""
          />
        </PartnerGlobalSearchProvider>
      }
    />

    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.INVENTORY_ARCHIVE}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView heading="Inventory" noMargin>
            <>
              <InventoryTabs key="tabs" />
              <InventoryArchive />
            </>
          </PortalView>
        </PartnerGlobalSearchProvider>
      }
    />
    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.INVENTORY_UNCLAIMED}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView heading="Inventory" noMargin>
            <>
              <InventoryTabs key="tabs" />
              <UnclaimedInventory />
            </>
          </PortalView>
        </PartnerGlobalSearchProvider>
      }
    />

    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.INVENTORY_UNCLAIMED_SUGGESTION}
      children={<UnclaimedSuggestion />}
    />

    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.INVENTORY}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView heading="Inventory" noMargin>
            <>
              <InventoryTabs key="tabs" />
              <Inventory />
            </>
          </PortalView>
        </PartnerGlobalSearchProvider>
      }
    />
    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.CLAIMS_ARCHIVE}
      children={
        <PortalView
          children={
            <FilterClaimProvider isArchive>
              <ClaimsArchive />
            </FilterClaimProvider>
          }
          heading="Archived claims"
          leftArrowText="Claims"
          leftArrowLink={PartnerRoutesEnum.CLAIMS}
          removeMobilePadding
        />
      }
    />

    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.CLAIMS}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView
            children={
              <FilterClaimProvider>
                <Claims />
              </FilterClaimProvider>
            }
            heading="Claims"
            removeMobilePadding
          />
        </PartnerGlobalSearchProvider>
      }
    />
    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.METRICS}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView children={<Metrics partnerId="" />} heading="Metrics" />
        </PartnerGlobalSearchProvider>
      }
    />
    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.MATCHES}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView
            children={<Matches partnerId="" />}
            heading="Potential matches"
            removeMobilePadding
          />
        </PartnerGlobalSearchProvider>
      }
    />
    <PrivatePartnerRoute
      exact
      path={"/partner/matches/matching"}
      children={
        <PartnerGlobalSearchProvider>
          <Matching />
        </PartnerGlobalSearchProvider>
      }
    />
    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.CLAIM}
      children={
        <PartnerClaimProvider>
          <PartnerSingleClaimView />
        </PartnerClaimProvider>
      }
    />
    <PrivatePartnerRoute exact path={PartnerRoutesEnum.ITEM} children={<ItemDetails />} />
    <PrivatePartnerRoute exact path={PartnerRoutesEnum.ITEM_EDIT} children={<EditItem />} />
    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.SINGLE_RETURN}
      children={<SingleReturnView />}
    />
    <PrivatePartnerRoute
      exact
      path={PartnerRoutesEnum.RETURNS}
      children={
        <PartnerGlobalSearchProvider>
          <PortalView children={<Returns />} heading="Returns" removeMobilePadding />
        </PartnerGlobalSearchProvider>
      }
    />
    <PrivatePartnerRoute
      path={PartnerRoutesEnum.RETURNS_ARCHIVE}
      children={
        <PortalView
          children={<ReturnsArchive partnerId="" />}
          heading="Completed returns"
          leftArrowText="Returns"
          leftArrowLink={PartnerRoutesEnum.RETURNS}
          removeMobilePadding
        />
      }
    />
    <PrivateRoute
      exact
      path={"/shippingcosts"}
      children={<PortalView children={<ShippingCosts />} heading="Shipping Costs" />}
    />
    <Route path="/claim/:id/notifications">
      <ClaimEmailIntervalPage />
    </Route>
    <Route
      exact
      path={PartnerRoutesEnum.WELCOME}
      children={<WelcomeView children={<Welcome />} heading="Welcome" />}
    />

    <Route exact path={PartnerRoutesEnum.SIGN_IN} children={<PartnerSignIn />} />

    <Route
      exact
      path="/sign-in"
      children={
        <UserLoginContextProvider>
          <Login />
        </UserLoginContextProvider>
      }
    />

    <Route
      exact
      path={PartnerRoutesEnum.FORGOT_PASSWORD}
      children={<WelcomeView children={<ForgotPassword />} heading="Forgot Password" />}
    />
    <Route
      exact
      path={PartnerRoutesEnum.RESET_PASSWORD}
      children={<WelcomeView children={<ResetPassword />} heading="Reset Password" />}
    />
    <PrivateRoute
      exact
      path={"/support"}
      children={<PortalView children={<Support />} heading="Support" />}
    />
    {/* Redirects on dead / broken urls 3 cases:
      1. if not logged in PrivatePartnerRoute will redirect to "/"
      2. if logged into a partner redirects to /partner/overview
      3. if logged into a user PrivatePartnerRoute will redirect to "/" which then redirects to /claims */}
    <AnonRedirect path="/*" />
  </Switch>
);

export default Router;
