import {
  LocationSearchResponse,
  PartnerReturn,
  PartnerStorageLocation,
  UserMatchFromIdResponse,
} from "./actions";

export enum MatchOrder {
  WORD_SCORE_ASCENDING = "word_score",
  WORD_SCORE_DESCENDING = "-word_score",
  MATCH_SCORE_ASCENDING = "match_score",
  MATCH_SCORE_DESCENDING = "-match_score",
}

export enum MatchStatus {
  PENDING = "PENDING",
  MATCHED = "MATCHED",
  NOT_A_MATCH = "NOT_A_MATCH",
  OTHER_MATCH_CHOSEN = "OTHER_MATCH_CHOSEN",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export enum ClaimStatus {
  NEW = "NEW",
  NO_MATCHES = "NO_MATCHES",
  HAS_MATCHES = "HAS_MATCHES",
  CANCELED = "CANCELED",
  MATCHED = "MATCHED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
}

export enum ItemStatus {
  UNCLAIMED = "UNCLAIMED",
  NO_MATCHES = "NO_MATCHES",
  HAS_MATCHES = "HAS_MATCHES",
  MATCHED = "MATCHED",
  COMPLETED = "COMPLETED",
}

export enum UnclaimedStatus {
  DONATED = "DONATED",
  DESTROYED = "DESTROYED",
  SOLD = "SOLD",
  STORED = "STORED",
  MORE_LIFE = "MORE_LIFE",
  OTHER = "OTHER",
  GIVEN_TO_STAFF = "GIVEN_TO_STAFF",
  RESTOCKED = "RESTOCKED",
  NULL = "NULL",
}

export enum ReturnStatus {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  CANCELED = "CANCELED",
}

export enum ShipmentStatus {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  PENDING = "PENDING",
  SHIPPED = "SHIPPED",
  PACKAGED = "PACKAGED",
  PROCESSING = "PROCESSING",
  DELIVERED = "DELIVERED",
}

export type ContactDetails = {
  fullName: string;
  email: string;
  phoneNumber: string;
};

export enum UserRoles {
  USER = "USER",
  PARTNER_USER = "PARTNER_USER",
}

export type ContactDetailsRequest = {
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
};

export type Partner = {
  created_at: Date;
  default_recovery_method: string;
  id: string;
  logo: string | null | undefined;
  modified_at: Date;
  name: string;
  recovery_methods: string[];
  slug: string;
  locations?: LocationSearchResponse | null;
};

export type Country = {
  id: string;
  name: string;
  iso_code: string;
};

export type State = {
  id: string;
  name: string;
  iso_code: string;
  country: Country;
};

export type Address = {
  id: string;
  address1: string;
  address2?: string;
  postal_code: string;
  city: string;
  region_code: string;
  country_code: string;
};

export type PartnerLocationCity = {
  id: string;
  name: string;
  state: State;
  country: string;
  image?: string;
  icon_image?: string;
};

export type PartnerLocationAddress = {
  id: string;
  address1: string;
  address2?: string;
  postal_code: string;
  city: string;
  region_code: string;
  country_code: string;
};

export type Location = {
  id: string;
  name: string;
  slug: string;
  partner: Partner;
  address: Address;
  image: string;
  icon_image: string;
  pickup_notes: string;
};

export type PartnerLocation = {
  id: string;
  created_at: Date;
  modified_at: Date;
  name: string;
  slug: string;
  address: PartnerLocationAddress;
  image: string | null;
  icon_image: string | null;
};

export type Category = {
  id: string;
  name: string;
  description?: string;
  order?: number;
  icon?: string;
};

export type Match = {
  id: string;
  item: string;
  claim: string;
  matched_by_user?: number;
  matched_by_engine: boolean;
  match_score: number;
  status: MatchStatus;
  address?: string;
  accepted_at: string;
  return: string;
};

export type UserMatch = {
  id: string;
  claim: string;
  item: string;
  match_score: number;
  pickup_notes: string;
  return: string;
  status: MatchStatus;
};

export type UserMatchApprove = {
  claim: string;
  id: string;
  item: string;
  match_score: string;
  pickup_notes: string;
  return: string;
  status: MatchStatus;
};

export type CreateMatchRequest = {
  partnerId: string;
  match: {
    claim: string;
    item: string;
    address?: string;
  };
};

export type Claim = {
  id: string;
  created_at: string;
  modified_at: string;
  name: string;
  description: string;
  category: Category;
  status: ClaimStatus;
  match?: string;
  lost_location: Location;
  lost_at: string;
  matchData?: UserMatchFromIdResponse;
  returnData?: UserReturn;
  claimImages?: ArtifactImage[];
};

export type ArtifactImage = {
  id: string;
  image: string;
  index: number;
  created_at: Date;
  modified_at: Date;
};

export type ArtifactImagePreview = {
  id: string;
  preview: string;
};

export type ImagePreview = Pick<ArtifactImagePreview, "preview">;

export type Item = {
  id: string;
  created_at: string;
  modified_at: string;
  name: string;
  description?: string;
  category: string;
  lost_location: Location;
  lost_at: string;
  value: number;
  images: ArtifactImage[];
};

export type PartnerItem = {
  id: string;
  created_at: Date;
  modified_at: Date;
  name: string;
  inventoryId?: string | null;
  description: string;
  category: string;
  status: ItemStatus;
  unclaimed_status?: UnclaimedStatus;
  suggested_unclaimed_status?: UnclaimedStatus;
  unclaimed_status_set_at?: Date;
  lost_location: string;
  current_location?: string;
  storage_location?: string | PartnerStorageLocation;
  collected_at: Date;
  created_by: User;
  images: ArtifactImage[];
  return_object: PartnerReturn;
};

export type UserItem = {
  id: string;
  category: string;
  description: string;
  images: ArtifactImage[];
  name: string;
  collected_at: string;
  lost_location: Location;
};

export type UserReturn = {
  created_at: string;
  id: string;
  match: string;
  modified_at: string;
  recovery_method: string;
  returned_at: string;
  shipment: string;
  status: ReturnStatus;
};

export type PartnerClaimCanceledReason =
  | "I_HAD_IT_THE_WHOLE_TIME"
  | "I_FOUND_IT_SOMEWHERE_ELSE"
  | "I_REPLACED_IT"
  | "I_DONT_WANT_IT_ANYMORE"
  | "OTHER";

export type PartnerClaim = {
  id: string;
  name: string;
  description: string;
  canceled_reason?: PartnerClaimCanceledReason;
  canceled_reason_other?: string;
  canceled_at?: Date;
  category: string;
  status: ClaimStatus;
  lost_location: string;
  created_at: Date;
  lost_at: Date;
  images: ArtifactImage[];
  user: User;
  return_object: PartnerReturn;
};

export type CreatePartnerClaimRequest = {
  partnerId: string;
  claim: {
    name: string;
    description: string;
    category: string;
    lost_location: string;
    lost_at: Date;
    user: string;
    exclude_from_matching?: boolean;
  };
};

export type RecoveryMethod = {
  id: string;
  name: string;
  value: RecoveryMethodsEnum;
  description: string;
};

export type User = {
  id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
};

export interface FormattedClaim {
  categoryName: string;
  date: Date;
  description: string;
  id: string;
  images: ArtifactImage[];
  lostLocationName: string;
  name: string;
  originalClaim: PartnerClaim;
  status: ClaimStatus;
  user: User;
}

export interface FormattedItem {
  categoryName: string;
  date: Date;
  description: string;
  id: string;
  inventoryId?: string | null;
  images: ArtifactImage[];
  lostLocationName: string;
  name: string;
  originalItem: PartnerItem;
  status: ItemStatus | UnclaimedStatus;
  storageLocation?: string;
}

export type FormattedArtifact =
  | Omit<FormattedClaim, "originalClaim">
  | Omit<FormattedItem, "originalItem">;

export enum ArtifactType {
  InventoryItem,
  Claim,
}

export enum RecoveryMethodsEnum {
  LOCAL_PICKUP = "LOCAL_PICKUP",
  SHIPPING_COST_COVERED = "SHIPPING_COST_COVERED",
  SHIPPING_COST_NOT_COVERED = "SHIPPING_COST_NOT_COVERED",
}
