import { AuthProvider } from "client/context";
import useFetchStorageLocations from "client/hooks/data/partner/useFetchStorageLocations";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import useCategories from "client/hooks/data/user/useCategories";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  useAddItem,
  useAddItemImage,
  useDeleteItemImage,
  useUpdateItemDeprecated,
} from "../../../actions/partnerItems";
import {
  ArtifactDetails,
  ArtifactDetailsForm,
  useArtifactDetailsForm,
} from "../../../components/ArtifactForms";
import ReviewArtifactDetails from "../../../components/ArtifactForms/ReviewArtifactDetails";
import Button from "../../../components/Button";
import { ButtonModes } from "../../../components/Button/Button.types";
import List from "../../../components/List";
import SimpleHeader from "../../../components/SimpleHeader";
import SmallConfirmationModal from "../../../components/SmallConfirmationModal";
import history from "../../../context/history";
import { usePartnerUserDispatch, usePartnerUserState } from "../../../context/PartnerUser";
import { updateCurrentItemAndImages } from "../../../context/PartnerUser/actions";
import { ItemStepCopy, UpdateItemStepCopy } from "../../../copy/artifactFormCopy";
import { isLgQuery } from "../../../helpers/mediaQuery";
import type {
  ArtifactImage,
  Claim,
  ImageSearchResponse,
  PartnerItem,
  PartnerLocation,
} from "../../../types";
import { mountBodyGray6, unmountBodyGray6 } from "../../../utils/bodyStyle";
import SubmittedPage from "./steps/SubmittedPage/SubmittedPage";
import { ItemSteps } from "./types";

export const Content = styled.main`
  background-color: ${props => props.theme.colors.gray6};
  .styled-content-container {
    margin-top: -1.2rem;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadows.md};
    border-radius: ${props => props.theme.borderRadius.lg};
    padding: 0;
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  max-width: ${props => props.theme.widths.claimMax};
  height: fit-content;
  &.header-style {
    height: 126.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

interface urlParams {
  urlPartnerSlug: string;
  urlLocation: string;
}

export interface AddEditItemProps {
  item?: PartnerItem;
  itemImages?: ImageSearchResponse;
}

const ItemForm = ({ item, itemImages }: AddEditItemProps) => {
  const { urlPartnerSlug, urlLocation } = useParams<urlParams>();
  const [itemId, setItemId] = useState<string>("");
  const [imageIdsToDelete, setImageIdsToDelete] = useState<string[]>([]);
  const [itemDetailsOverride, setItemDetailsOverride] = useState<ArtifactDetails>();
  const artifactDetailsForm = useArtifactDetailsForm();
  const images = artifactDetailsForm.watch("images");
  const isLg = isLgQuery();

  const isEditingExistingItem = item !== undefined;
  const stepCopy = isEditingExistingItem ? UpdateItemStepCopy : ItemStepCopy;

  const [step, setStep] = useState<ItemSteps>(
    (isEditingExistingItem && ItemSteps.ITEM_DETAILS) || ItemSteps.LOCATION_SELECTION,
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [urlLocationComputed, setUrlLocationComputed] = useState<boolean>(false);
  const [lessThanOneLocation, setLessThanOneLocation] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [entryPoint, setEntryPoint] = useState<ItemSteps>(
    (isEditingExistingItem && ItemSteps.ITEM_DETAILS) || ItemSteps.LOCATION_SELECTION,
  );

  const [loadingUrl, setLoadingUrl] = useState<boolean>(true); // TODO add a loading page to communicate to user
  // Original of editing form, so they can be reset

  const partnerUserDispatch = usePartnerUserDispatch();

  const partnerUserState = usePartnerUserState();
  const partnerId = useCurrentPartner();
  const { partnerLocations } = usePartnerLocations(partnerId);
  const categories = useCategories();

  let initialLocation: PartnerLocation | undefined = undefined;
  if (isEditingExistingItem && partnerLocations && partnerLocations.length > 0) {
    initialLocation = partnerLocations.find(l => l.id === item?.lost_location);
  }
  const [selectedLocation, setSelectedLocation] = useState<PartnerLocation | undefined>(
    initialLocation,
  );

  const { storageLocations } = useFetchStorageLocations(selectedLocation);

  const cancelModalHeader = isEditingExistingItem ? "Cancel item updates?" : "Cancel item report?";

  const cancelOrBackBtnText =
    entryPoint === ItemSteps.LOCATION_SELECTION || lessThanOneLocation ? "Cancel" : "Back";

  const getLocationText = () => {
    let toReturn = "";
    if (selectedLocation) {
      toReturn += selectedLocation["name"];
    }
    return toReturn;
  };

  const showMobileCancel = () => {
    if (step === ItemSteps.LOCATION_SELECTION && (!urlLocation || urlLocationComputed)) {
      return true;
    }
    return false;
  };

  const handleBackLocationsStep = () => {
    setStep(ItemSteps.LOCATION_SELECTION);
    setSelectedLocation(undefined);
  };

  const handleRedirect = () => {
    if (isEditingExistingItem) {
      history.push(`/partner/item/${item.id}`, history.location.state);
      // if /additem is opened in a new tab
    } else if (window.history.length === 2) {
      history.push("/partner/overview");
    } else {
      history.goBack();
    }
  };

  const backOrCancelFunctionality = () => {
    if (isEditingExistingItem) {
      if (step === ItemSteps.ITEM_DETAILS) {
        if (artifactDetailsForm.formState.isDirty) {
          setShowModal(true);
        } else {
          handleRedirect();
        }
      } else {
        setStep(step - 1);
      }

      return;
    }

    if (step === ItemSteps.LOCATION_SELECTION) {
      // If a form is dirty show the pop-up, otherwise just go home
      if (artifactDetailsForm.formState.isDirty) {
        setShowModal(true);
      } else {
        handleRedirect();
      }
    } else if (step === ItemSteps.ITEM_DETAILS) {
      lessThanOneLocation
        ? artifactDetailsForm.formState.isDirty
          ? setShowModal(true)
          : handleRedirect()
        : handleBackLocationsStep();
    } else {
      setStep(step - 1);
    }
  };

  const handleLessThanOneLocation = () => {
    setStep(ItemSteps.ITEM_DETAILS);
    setLessThanOneLocation(true);
    // Set url to just the partner
    if (urlLocation) {
      window.history.pushState(
        { path: `/lost/flow?p=${urlPartnerSlug}` },
        "",
        `/lost/flow?p=${urlPartnerSlug}`,
      );
    }
  };

  const onLeftBtnClick = () => {
    setShowModal(false);
  };

  const onRightBtnClick = () => {
    handleRedirect();
  };

  const onCancelOrBack = () => {
    backOrCancelFunctionality();
  };

  const onSubmitDetailsForm = artifactDetailsForm.handleSubmit(() => {
    setStep(ItemSteps.REVIEW_DETAILS);
    setIsEditing(false);
  });

  const onItemUpdated = (
    artifact: Claim | PartnerItem,
    response: PartnerItem | null,
    updatedImages: ArtifactImage[],
  ) => {
    const updatedItem = Object.assign({}, artifact, response);

    updateCurrentItemAndImages(partnerUserDispatch, updatedItem, {
      count: updatedImages?.length,
      results: updatedImages,
    });
  };

  useEffect(() => {
    artifactDetailsForm.reset();
    // Used to make the body gray6 (default it is white)
    mountBodyGray6();
    // cleanup once this page is left
    return function cleanup() {
      unmountBodyGray6();
    };
  }, []);

  useEffect(() => {
    if (partnerLocations?.length === 0) {
      //TODO this will break address
      handleLessThanOneLocation();
    } else if (partnerLocations?.length === 1) {
      setSelectedLocation(partnerLocations[0]);
      handleLessThanOneLocation();
    } else if (urlLocation && partnerLocations) {
      let urlLocationName;
      for (let i = 0; i < partnerLocations.length; i++) {
        if (urlLocation === partnerLocations[i]["slug"]) {
          urlLocationName = partnerLocations[i]["name"];
          break;
        }
      }

      setUrlLocationComputed(true);
      setSelectedLocation(urlLocationName);

      // When editing an item, we know the location and have already skipped ahead to ITEM_DETAILS.
      if (!isEditingExistingItem) {
        setStep(urlLocationName ? ItemSteps.ITEM_DETAILS : ItemSteps.LOCATION_SELECTION);
        setEntryPoint(urlLocationName ? ItemSteps.ITEM_DETAILS : ItemSteps.LOCATION_SELECTION);
      }
    } else {
      setLessThanOneLocation(false);
    }
    // End loading period, show site
    setLoadingUrl(false);
  }, [partnerLocations]);

  return (
    <>
      {step <= ItemSteps.ITEM_DETAILS && (
        <SmallConfirmationModal
          show={showModal}
          leftBtnOnClick={onLeftBtnClick}
          leftBtnText={"Never mind"}
          rightBtnText="Yes, cancel"
          rightBtnOnClick={onRightBtnClick}
          header={cancelModalHeader}
        />
      )}
      {step !== ItemSteps.SUBMITTED ? (
        <>
          <SimpleHeader
            onLeftArrowClick={() => {
              backOrCancelFunctionality();
            }}
            subHeaderText={getLocationText()}
            title={(!loadingUrl && stepCopy[step]?.title) || ""}
          />
          {!loadingUrl && (
            <div>
              <Content className="row mx-0 justify-content-center px-3 px-lg-0">
                <Wrapper
                  className={
                    step === ItemSteps.REVIEW_DETAILS
                      ? "mb-3 styled-content-container zIndex1"
                      : "mb-5 styled-content-container zIndex1"
                  }
                >
                  {step === ItemSteps.LOCATION_SELECTION && (
                    <List
                      items={partnerLocations?.sort((a, b) => a.name.localeCompare(b.name))}
                      buttonText="Cancel"
                      onSelection={(selection: PartnerLocation) => {
                        setSelectedLocation(selection);
                        setStep(ItemSteps.ITEM_DETAILS);
                      }}
                      onButtonClick={() => {
                        backOrCancelFunctionality();
                      }}
                      subheading={stepCopy[step].heading || ""}
                    />
                  )}
                  {step === ItemSteps.ITEM_DETAILS && partnerLocations && storageLocations && (
                    <ArtifactDetailsForm
                      {...artifactDetailsForm}
                      artifact={item}
                      artifactImages={itemImages}
                      categories={categories.data ?? []}
                      storageLocations={storageLocations}
                      contextState={partnerUserState}
                      detailsOverride={itemDetailsOverride}
                      entryPoint={
                        partnerLocations.length < 2
                          ? ItemSteps.ITEM_DETAILS
                          : ItemSteps.LOCATION_SELECTION
                      }
                      isClaim={false}
                      isEditing={isEditing}
                      onCancelOrBack={onCancelOrBack}
                      onSubmit={onSubmitDetailsForm}
                      setImageIdsToDelete={setImageIdsToDelete}
                      stepCopy={stepCopy}
                    />
                  )}

                  {step === ItemSteps.REVIEW_DETAILS && categories && selectedLocation && (
                    <ReviewArtifactDetails
                      {...artifactDetailsForm}
                      artifact={item}
                      categories={categories.data ?? []}
                      storageLocations={storageLocations}
                      existingArtifactImages={
                        itemImages !== undefined ? itemImages : { count: 0, results: [] }
                      }
                      images={images}
                      imageIdsToDelete={imageIdsToDelete}
                      isClaim={false}
                      onArtifactUpdated={onItemUpdated}
                      setArtifactId={setItemId}
                      setImageIdsToDelete={setImageIdsToDelete}
                      setIsEditing={setIsEditing}
                      setArtifactDetailsOverride={setItemDetailsOverride}
                      setStep={setStep}
                      submitArtifact={true}
                      selectedLocation={selectedLocation}
                      useCreateArtifact={useAddItem}
                      useCreateArtifactImage={useAddItemImage}
                      useDeleteArtifactImage={useDeleteItemImage}
                      useUpdateArtifact={useUpdateItemDeprecated}
                    />
                  )}
                </Wrapper>
              </Content>
              {showMobileCancel() && (
                <div className="row mx-0 justify-content-center w-100 d-flex d-lg-none py-1 mt-n5">
                  <div className="w-50 mt-2 d-flex justify-content-center">
                    {" "}
                    {/*  TODO hide on subsequent steps */}
                    <Button
                      ariaLabel={cancelOrBackBtnText}
                      className="row mx-0 justify-content-center align-items-center"
                      mode={ButtonModes.open}
                      onClick={() => {
                        backOrCancelFunctionality();
                      }}
                      text={cancelOrBackBtnText}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <AuthProvider>
          <SubmittedPage
            isLarge={isLg}
            imageUrl={images?.length > 0 ? images[0].preview : ""}
            selectedLocation={selectedLocation && selectedLocation["name"]}
            stepCopy={stepCopy}
            isEditingItem={isEditingExistingItem}
            itemId={itemId}
            editAgain={() => setStep(ItemSteps.ITEM_DETAILS)}
            itemName={artifactDetailsForm.getValues().itemName}
            foundDate={artifactDetailsForm.getValues().foundDate}
          />
        </AuthProvider>
      )}
    </>
  );
};

export default ItemForm;
