import { ItemStatus } from "../../../../types";
import { FilterItem } from "../../../../ui-components/FilterDropdown/types";

export type InventoryFilters = {
  status: FilterItem<ItemStatus | "ALL">;
  category: FilterItem;
  date_before: Date | null;
  date_after: Date | null;
  storageLocation: FilterItem;
};

export type InventoryFilterValues = {
  status: ItemStatus | "ALL";
  category: string;
  date_before: Date | null;
  date_after: Date | null;
  storageLocation: string;
};

export const initialCategoryFilter: FilterItem = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export const initialStatusFilter: FilterItem<ItemStatus | "ALL"> = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export const initialStorageLocationFilter: FilterItem = {
  name: "Showing all",
  count: 0,
  value: "ALL",
};

export type FilterOptions = keyof InventoryFilters;
