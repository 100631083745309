import { queryOptions } from "@tanstack/react-query";
import { PartnerLocationRequestFilters, RecoveryMethodResponse } from "client/types";
import fetchLocations from "../fetchFunctions/fetchLocations";
import fetchPartner from "../fetchFunctions/fetchPartner";
import fetchPartnerCategory from "../fetchFunctions/fetchPartnerCategory";
import fetchPartnerRecoveryMethods from "../fetchFunctions/fetchPartnerRecoveryMethods";
import fetchStorageLocations from "../fetchFunctions/fetchStorageLocations";

const baseQueries = {
  all: () => ["partner"],
  recoveryMethods: () =>
    queryOptions({
      queryKey: [...baseQueries.all(), "recoveryMethods"],
      queryFn: fetchPartnerRecoveryMethods,
      select: (data: RecoveryMethodResponse) => data.results,
    }),
  recoveryMethodsFiltered: (filterIds: string[]) =>
    queryOptions({
      ...baseQueries.recoveryMethods(),
      enabled: filterIds.length > 0,
      select: data => data.results.filter(method => filterIds.includes(method.id)),
    }),
  categories: () => [...baseQueries.all(), "categories"],
  category: (categoryId: string | undefined) =>
    queryOptions({
      queryKey: [...baseQueries.categories(), categoryId],
      queryFn: () => (categoryId ? fetchPartnerCategory(categoryId) : undefined),
      select: data => data,
      enabled: !!categoryId,
    }),
  partner: (partnerId: string) => [...baseQueries.all(), partnerId],
  info: (partnerId: string) =>
    queryOptions({
      queryKey: [...baseQueries.partner(partnerId), "info"],
      queryFn: () => fetchPartner(partnerId),
      select: data => data,
    }),
  locations: (partnerId: string) =>
    queryOptions({
      queryKey: [...baseQueries.partner(partnerId), "locations"],
      queryFn: () => fetchLocations({ partnerId }),
    }),
  location: ({ partnerId, locationId }: { partnerId: string; locationId: string }) =>
    queryOptions({
      queryKey: [...baseQueries.locations(partnerId).queryKey, locationId],
      queryFn: () => fetchLocations({ partnerId, locationId }),
    }),
  locationFiltered: ({
    partnerId,
    locationId,
    filters,
  }: {
    partnerId: string;
    locationId: string;
    filters?: PartnerLocationRequestFilters;
  }) =>
    queryOptions({
      queryKey: [...baseQueries.location({ partnerId, locationId }).queryKey, filters],
      queryFn: () => fetchLocations({ partnerId, locationId, filters }),
    }),
  storageLocations: ({ partnerId, locationId }: { partnerId: string; locationId: string }) => {
    return queryOptions({
      queryKey: [...baseQueries.location({ partnerId, locationId }).queryKey, "storage"],
      queryFn: () => fetchStorageLocations({ partnerId, locationId }),
    });
  },
};

export default baseQueries;
