import { useQueries } from "@tanstack/react-query";
import { RecoveryMethod, RecoveryMethodsEnum } from "client/types";
import queryFactory from "./queryFactory";

const getShippingMethods = (recoveryMethods: RecoveryMethod[]) =>
  recoveryMethods.filter(
    method =>
      method.value === RecoveryMethodsEnum.SHIPPING_COST_COVERED ||
      method.value === RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED,
  );

export default (filterRecoveryMethodIds?: string[]) => {
  const data = useQueries({
    queries: [
      queryFactory.recoveryMethods(), // all possible recovery methods across all partners
      queryFactory.recoveryMethodsFiltered(filterRecoveryMethodIds ?? []), // filtered set for a partner
    ],
    combine: results => {
      const recoveryMethods = results[0].data ?? [];
      const filteredRecoveryMethods = results[1].data ?? [];
      return {
        allRecoveryMethods: {
          data: recoveryMethods,
          localPickupMethod: recoveryMethods.find(
            method => method.value === RecoveryMethodsEnum.LOCAL_PICKUP,
          ),
          shippingMethods: getShippingMethods(recoveryMethods),
        },
        filteredRecoveryMethods: {
          data: filteredRecoveryMethods,
          localPickupMethod: recoveryMethods.find(
            method => method.value === RecoveryMethodsEnum.LOCAL_PICKUP,
          ),
          shippingMethods: getShippingMethods(filteredRecoveryMethods),
        },
        isLoading: results.some(result => result.isLoading),
        isPending: results.some(result => result.isPending),
        isError: results.some(result => result.isError),
      };
    },
  });

  return data;
};
