import classNames from "classnames";
import { Text } from "client/ui-components";
import { regionCodeToName } from "client/utils/countries";
import React, { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { LocationPin, PickupReady } from "../../../../../assets/icons/icons";
import Button from "../../../../../components/Button";
import { ButtonModes } from "../../../../../components/Button/Button.types";
import { ClaimDetailsContext } from "../../../../../context/ClaimDetails";
import theme from "../../../../../theme";
import { RecoveryMethodsEnum } from "../../../../../types";
import { AddressText, PickupWrapper } from "./styles";

interface PickupProps {
  changeRecoveryMethod?: Dispatch<SetStateAction<RecoveryMethodsEnum>>;
  modalVersion?: boolean;
  handleApprove?: () => void;
  approveLoading?: boolean;
}

const Pickup: React.FC<PickupProps> = ({
  changeRecoveryMethod,
  modalVersion,
  handleApprove,
  approveLoading,
}) => {
  const { claim } = useContext(ClaimDetailsContext);
  const pickupAddress = useMemo(() => claim?.lost_location.address, [claim]);
  const pickupInstructions = useMemo(() => claim?.lost_location.pickup_notes, [claim]);
  const hydratedAddress = useMemo<Array<string>>(() => {
    if (pickupAddress === undefined) return [];

    const { address1, address2, postal_code, city, region_code } = pickupAddress;

    return [
      [address1, address2].join(" "),
      [`${city},`, regionCodeToName(region_code), postal_code].join(" "),
    ];
  }, [pickupAddress]);

  return (
    <PickupWrapper className={`mb-35 ${modalVersion ? "pt-lg-2" : "pt-lg-35"}`}>
      <div
        className={classNames({
          "border rounded-3 py-4 px-35 mb-35": modalVersion,
        })}
      >
        <div className={classNames("mb-35", { "pt-1": modalVersion })}>
          <div className="d-flex align-items-center mb-1">
            <PickupReady
              height={modalVersion ? 34 : 24}
              width={modalVersion ? 34 : 24}
              titleId="ready-for-pickup-icon"
              accessibilityTitle="Ready for pickup icon"
            />
            <Text
              fontWeight={theme.ui.fontWeights.bold}
              className={classNames("fw-bold ms-25 fs-lg mb-0", {
                "modal-version": modalVersion,
              })}
            >
              {modalVersion ? "Pick up in person" : "Ready to pick up!"}
            </Text>
          </div>
          <p className="copy text-start mb-0">{pickupInstructions}</p>
        </div>

        <div className="address p-35 d-flex align-items-center">
          <LocationPin
            titleId="location-pin-icon"
            accessibilityTitle="Location Pin Icon"
            color={theme.colors.gray3}
          />

          <AddressText className="mb-0 ms-25">
            {hydratedAddress[0]}
            <br />
            {hydratedAddress[1]}
          </AddressText>
        </div>
        {modalVersion && (
          <Button
            className="w-100 mt-35 mb-1"
            ariaLabel="Pick up in person"
            text={"Pick up in person"}
            mode={ButtonModes.primary}
            tabIndex={0}
            onClick={handleApprove}
            loading={approveLoading}
            disabled={approveLoading}
          />
        )}
      </div>
      {modalVersion && (
        <p className="text-center pb-35">
          Can't pick it up?{" "}
          <span
            className="cursor-pointer link-primary fw-bold"
            onClick={() =>
              changeRecoveryMethod
                ? changeRecoveryMethod(RecoveryMethodsEnum.SHIPPING_COST_NOT_COVERED)
                : null
            }
            tabIndex={0}
            data-testid="haveItShippedLink"
          >
            Have it shipped
          </span>
        </p>
      )}
    </PickupWrapper>
  );
};

export default Pickup;
