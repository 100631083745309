import { PartnerItemsCountByStorageResponse } from "client/types";
import { fetchApiData } from "client/utils/fetchApiData";
import { formatFilters, OptionalCountQueryFilters } from "../partnerItemCounts";
import { initialStorageLocationFilter } from "../partnerItemCounts/useStorageLocationCounts";

export default ({
  partnerId,
  queryObject,
}: {
  partnerId: string;
  queryObject: OptionalCountQueryFilters;
}): Promise<PartnerItemsCountByStorageResponse> => {
  const query = formatFilters({
    ...queryObject,
    storageLocation: initialStorageLocationFilter.value,
  });
  return fetchApiData({
    url: `/api/partner/partners/${partnerId}/items/count-by-storage/?${query}`,
  });
};
