import usePartner from "client/hooks/data/partner/usePartner";
import usePartnerRecoveryMethods from "client/hooks/data/partner/usePartnerRecoveryMethods";
import useCurrentPartner from "client/utils/useCurrentPartner";
import React, { Dispatch, SetStateAction } from "react";
import Button from "../../../../components/Button";
import { ButtonModes, ButtonTypes } from "../../../../components/Button/Button.types";
import ThreeDotsDropdown, {
  DropdownListItem,
} from "../../../../components/Dropdowns/ThreeDotsDropdown";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import { PartnerReturn, PartnerTextButtonEnum, RecoveryMethodsEnum } from "../../../../types";
import { PartnerShipment } from "../../../../types/actions";
import { ReturnStatus, ShipmentStatus } from "../../../../types/models";
import ActionDropdown from "../../ItemDetails/ActionsToolbar/ActionDropdown";

interface SingleReturnButtonsProps {
  returnData: PartnerReturn | null;
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
  setShowSwitchModal: Dispatch<SetStateAction<boolean>>;
  shipmentData?: PartnerShipment | null;
}

const SingleReturnButtons: React.FC<SingleReturnButtonsProps> = props => {
  const isLg = isLgQuery();
  const partnerId = useCurrentPartner();
  const { partner } = usePartner(partnerId);

  const { allRecoveryMethods } = usePartnerRecoveryMethods();

  const { returnData, setShowConfirmationModal, shipmentData, setShowSwitchModal } = props;
  const returnRecoveryMethod = allRecoveryMethods.data.find(
    recoveryMethod => recoveryMethod.id === returnData?.recovery_method,
  )?.value;

  const hasShipping = allRecoveryMethods.shippingMethods.some(method =>
    partner?.recovery_methods.includes(method.id),
  );

  const hasLocalPickup = allRecoveryMethods.localPickupMethod
    ? partner?.recovery_methods.includes(allRecoveryMethods.localPickupMethod.id)
    : false;

  const showMarkedAsPackaged =
    returnData?.status === ReturnStatus.PENDING &&
    shipmentData?.label_url &&
    shipmentData?.status === ShipmentStatus.PENDING;

  const localPickup = returnRecoveryMethod === RecoveryMethodsEnum.LOCAL_PICKUP;
  const isAwaitingPayment =
    returnData?.status === ReturnStatus.PENDING &&
    !localPickup &&
    !(shipmentData?.transactions ?? []).find(transaction => transaction.status === "SUCCESS");

  const isPackaged =
    returnData?.status === ReturnStatus.PENDING &&
    !isAwaitingPayment &&
    shipmentData?.status === ShipmentStatus.PACKAGED;

  const showPrintLabelButton =
    (!localPickup &&
      returnData?.status !== ReturnStatus.COMPLETED &&
      shipmentData?.label_url &&
      shipmentData.status === ShipmentStatus.PENDING) ||
    isPackaged;

  const labelButton: DropdownListItem = {
    label: PartnerTextButtonEnum.PRINT_LABEL,
    onClick: () => {
      const win = window.open(shipmentData?.label_url, "_blank");
      win?.focus();
    },
  };

  if (!returnData || !returnRecoveryMethod) {
    return <></>;
  }

  const returnItemNow: DropdownListItem = {
    label: PartnerTextButtonEnum.RETURN_NOW,
    onClick: () => {
      setShowConfirmationModal(true);
    },
  };
  const packagedButton: DropdownListItem = {
    label: PartnerTextButtonEnum.PACKAGED,
    onClick: () => {
      setShowConfirmationModal(true);
    },
  };
  const switchToPickupButton: DropdownListItem = {
    label: PartnerTextButtonEnum.SWITCH_TO_PICKUP,
    onClick: () => {
      setShowSwitchModal(true);
    },
  };

  const switchToShippingButton: DropdownListItem = {
    label: PartnerTextButtonEnum.SWITCH_TO_SHIPPING,
    onClick: () => {
      setShowSwitchModal(true);
    },
  };
  const dropdownListItems: DropdownListItem[] = [];
  if (showPrintLabelButton && !isLg) {
    dropdownListItems.push(labelButton);
  }
  if (localPickup || isAwaitingPayment) {
    dropdownListItems.push(returnItemNow);
  }
  if (showMarkedAsPackaged) {
    dropdownListItems.push(packagedButton);
  }
  if (isAwaitingPayment && hasLocalPickup) {
    dropdownListItems.push(switchToPickupButton);
  } else if (localPickup && hasShipping) {
    dropdownListItems.push(switchToShippingButton);
  }

  return (
    <>
      {!isLg && dropdownListItems.length > 0 && returnData.status !== ReturnStatus.COMPLETED && (
        <ActionDropdown options={dropdownListItems} />
      )}
      {isLg && showPrintLabelButton && (
        <Button
          ariaLabel={PartnerTextButtonEnum.PRINT_LABEL}
          data-testid="printLabel"
          mode={ButtonModes.success}
          type={ButtonTypes.submit}
          text={PartnerTextButtonEnum.PRINT_LABEL}
          className={"border-0 col px-45"}
          disabled={false}
          loading={false}
          onClick={() => {
            const win = window.open(shipmentData.label_url, "_blank");
            win?.focus();
          }}
        />
      )}
      {isLg && returnData.status !== ReturnStatus.COMPLETED && dropdownListItems.length > 0 && (
        <ThreeDotsDropdown buttonMode={ButtonModes.dark} listItems={dropdownListItems} />
      )}
    </>
  );
};

export default SingleReturnButtons;
